.ins-input-label {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	box-sizing: border-box;
	flex-shrink: 0;

	.v-input-label {
		font-size: 14px;
		color: #262626;
		line-height: 20px;
		margin-right: 2px;
	}

	.v-require-before {
		color: #EA281E;
		font-size: 16px;
		margin-top: 2px;
		margin-right: 2px;
		line-height: 20px;
	}

	.v-require-end {
		color: #EA281E;
		font-size: 16px;
		margin-top: 2px;
		margin-left: 2px;
		line-height: 20px;
	}

	.v-img-tips {
		width: 16px;
		height: 16px;
		//margin-left: 4px;
	}

}

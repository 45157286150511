.ins-dialog-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	box-sizing: border-box;
	z-index: 1000;
	margin: 0 auto;
	overflow: hidden;
	visibility: hidden;
	transition: visibility 270ms cubic-bezier(0.36, 0.66, 0.04, 1);

	.ins-dialog-overlay {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 510;
		opacity: 0;
		transition: opacity 270ms ease-in;
	}

	.ins-dialog-ctn {
		position: fixed;
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 520;
		box-sizing: border-box;
	}

	.location-top {
		width: 100%;
		max-height: 80%;
		top: 0;
		transform: translate3d(0, -100%, 0);
		transition: transform 270ms cubic-bezier(0.36, 0.66, 0.04, 1);
	}

	.location-bottom {
		width: 100%;
		bottom: 0;
		max-height: 80%;
		transform: translate3d(0, 100%, 0);
		transition: transform 270ms cubic-bezier(0.36, 0.66, 0.04, 1);
	}

	.location-center {
		width: 100%;
		max-height: 80%;
		margin: 0 auto;
		transform: scale(0, 0);
		opacity: 0;
		transition: transform 270ms cubic-bezier(0.36, 0.66, 0.04, 1);
	}

	.location-left {
		max-width: 80%;
		height: 100%;
		left: 0;
		transform: translate3d(-100%, 0, 0);
		transition: transform 270ms cubic-bezier(0.36, 0.66, 0.04, 1);
		align-items: flex-start;
	}

	.location-right {
		max-width: 80%;
		height: 100%;
		right: 0;
		transform: translate3d(100%, 0, 0);
		transition: transform 270ms cubic-bezier(0.36, 0.66, 0.04, 1);
		align-items: flex-end;
	}

	&--active {
		visibility: visible;
		.ins-dialog-overlay {
			opacity: 1;
		}
		.location-top {
			transform: translate3d(0, 0, 0);
		}
		.location-bottom {
			transform: translate3d(0, 0, 0);
		}
		.location-center {
			opacity: 1;
			transform: scale(1, 1);
		}
		.location-left {
			transform: translate3d(0, 0, 0);
		}
		.location-right {
			transform: translate3d(0, 0, 0);
		}
	}

}

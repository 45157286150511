.about-us-wrap {
  .about-us-content {
    width: 1224px;
    margin: 0 auto;
  }

  .banner_wrap {
    width: 100%;
    margin-bottom: 56px;
    position: relative;
    img {
      width: 100%;
    }

    .font_wrap {
      position: absolute;
      top: 0;
      width: 1224px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      img {
        width: 373px;
        height: auto;
      }
    }
  }

  .qa_nav_wrap {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 40px;
    cursor: pointer;
    .qa_nav {
      margin-right: 40px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.56);
      padding-bottom: 12px;

      &.active {
        font-weight: 600;
        color: rgba(225, 37, 27, 1);
        border-bottom: 2px solid rgba(225, 37, 27, 1);
        margin-bottom: -1px;
      }
    }
  }

  .fee_desc {
    line-height: 26px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);
  }

  .fee_desc_s {
    line-height: 26px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.56);
  }

  .mr_b {
    margin-bottom: 24px;
  }
  .f_w {
    font-weight: 600;
    color: #e1251b;
  }
}

.milepost-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 40px;
  }

  .header_img {
    width: 100%;
    margin-bottom: 54px;
    img {
      width: 100%;
    }
  }

  .thing-wrap {
    position: relative;
    .line {
      width: 2px;
      height: calc(100% - 16px);
      background-color: rgba(213, 216, 223, 1);
      position: absolute;
      left: 9px;
      z-index: 0;
      margin-top: 8px;
    }
  }
  .thing-item {
    .small-item {
      margin-bottom: 8px;
      .s_icon {
        margin-right: 44px;
        width: 20px;
        text-align: center;
      }

      .date {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.72);
        margin-right: 16px;
      }
      .thing {
        color: rgba(0, 0, 0, 0.56);
        line-height: 24px;
        flex: 1;
      }
    }

    img {
      position: relative;
      z-index: 1;
    }

    .big-item {
      margin-bottom: 30px;
      .s_icon {
        margin-right: 44px;
        width: 20px;
        text-align: center;
      }
      .year {
        width: 78px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        background: linear-gradient(
          219.02deg,
          rgba(191, 199, 212, 1) 0%,
          rgba(143, 155, 177, 1) 100%
        );
        border-radius: 0 14px 14px 14px;

        &.red_year {
          background: linear-gradient(
            144.22deg,
            rgba(234, 40, 30, 1) 0%,
            rgba(255, 138, 132, 1) 100%
          );
        }
      }
    }
  }

  .thing-flex {
    display: flex;
    align-items: flex-start;
  }

  .sub_title {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .sub_title_desc {
    line-height: 26px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);
    margin-bottom: 16px;
  }
}

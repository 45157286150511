.order-period {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	box-sizing: border-box;

	.v-item-wrap {
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		box-sizing: border-box;
		margin-top: 20px;
		margin-right: 20px;
		position: relative;

		&_label {
			font-size: 14px;
			color: #333333;
		}

		&_select {
			width: 276px;
			height: 32px;
			border-radius: 6px;
			margin-top: 6px;
		}

		&_input {
			width: 276px;
			height: 32px;
			border-radius: 6px;
			margin-top: 6px;
		}

		&_period {
			height: 32px;
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 6px;
		}

		&_error {
			font-size: 12px;
			color: red;
			position: absolute;
			left: 12px;
			bottom: -20px;
			z-index: 2;
		}

	}

	.v-period-mode-wrap {
		width: 276px;
		height: 32px;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		margin-top: 6px;
		.v-period-mode-item {
			flex: 1;
			height: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			font-size: 14px;
			font-weight: normal;
			color: #262626;
			line-height: 22px;
			border: 1px solid #E9E9E9;
			background-color: white;
			cursor: pointer;
			&:first-child {
				border-top-left-radius: 6px;
				border-bottom-left-radius: 6px;
			}
			&:last-child {
				border-top-right-radius: 6px;
				border-bottom-right-radius: 6px;
			}
			&:hover {
				border: 1px solid #E1251B;
			}
		}
		.v-period-mode-item-active {
			background-color: #E1251B;
			border: 1px solid #E1251B;
			font-size: 14px;
			font-weight: bold;
			color: #FFFFFF;
		}
	}

}

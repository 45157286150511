.production-info-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 24px;
  }

  .sub_title {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .sub_title_desc {
    height: 24px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .box_2 {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding-top: 20px;
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 82px;
    padding-right: 82px;
    padding-bottom: 4px;

    .box_2_item {
      width: 200px;
      height: 32px;
      background: #fff;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.64);
      line-height: 32px;
      text-align: center;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 16px;

      &:hover {
        border: 1px solid rgba(225, 37, 27, 0.56);
        color: #e1251b;
      }
    }
  }
}

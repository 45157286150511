.search_more {
  position: sticky;
  top: 100px;
  left: 0;
  width: 250px;
  height: 536px;
  .search_product {
    position: relative;
    margin-bottom: 18px;
    &:hover {
      box-shadow: 0 24px 56px 0 rgba(0, 0, 0, 4%);
      transition: all 0.2s;
    }
    img {
      width: 250px;
      &:hover {
        box-shadow: 0 24px 56px 0 rgba(0, 0, 0, 4%);
        transition: all 0.2s;
      }
    }

    .qrcode-canvas {
      position: absolute;
      top: 41px;
      left: 67px;
    }

    &_baogu {
      position: absolute;
      top: 41px;
      left: 67px;
      img {
        width: 128px;
        height: 128px;
      }
    }
  }
}

.insure_server_wrap {
  .banner_wrap {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }

    .font_wrap {
      position: absolute;
      top: 0;
      width: 1224px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      img {
        width: 373px;
        height: auto;
      }
    }
  }

  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 40px;
  }

  .content_wrap {
    width: 1224px;
    margin: 56px auto 0;
    display: flex;
    .left {
      margin-right: 24px;
      width: 950px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      padding: 32px;
    }
    .right {
      width: 250px;
      img {
        width: 100%;
      }
    }
  }

  .qa_nav_wrap {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 40px;
    cursor: pointer;
    .qa_nav {
      margin-right: 40px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.56);
      padding-bottom: 12px;

      &.active {
        font-weight: 600;
        color: rgba(225, 37, 27, 1);
        border-bottom: 2px solid rgba(225, 37, 27, 1);
        margin-bottom: -1px;
      }
    }
  }
  .news_list {
    display: flex;
    flex-wrap: wrap;
    .news_item {
      cursor: pointer;
      width: calc((100% - 42px) / 3);
      height: auto;
      margin-right: 14px;
      margin-bottom: 14px;
      border-radius: 8px;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 2px 5px rgb(0 0 0 / 15%);
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.08);
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.navbar_wrap {
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  height: 120px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  a {
    color: rgba(255, 255, 255, 1);
  }

  &.scroll_bg {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
    transition: all 0.3s;
    height: auto;
    a {
      color: rgba(0, 0, 0, 0.8);
    }

    .top_line {
      a {
        color: rgba(0, 0, 0, 0.56);
      }
      .divider {
        background: rgba(0, 0, 0, 0.12);
      }
    }

    .navbar_inner {
      .hot-line-wrap {
        .phone-label {
          color: rgba(0, 0, 0, 0.56);
        }

        .phone-value {
          color: #e1251b;
        }
      }

      .navbar_menu .narbar_item .activeNav {
        border-bottom: 2px solid #e1251b;
        color: #e1251b;
      }
    }
  }

  &.scroll_height {
    .top_line {
      height: 0;
      color: rgba(0, 0, 0, 0.56);
    }
  }

  .top_line {
    height: 41px;
    width: 1224px;
    margin: 0 auto;
    transition: height 0.3s;

    a {
      line-height: 17px;
      font-size: 12px;

      // color: #fff;
      &:hover {
        color: #e1251b;
      }

      &.selected {
        color: #e1251b;
      }
    }

    .divider {
      height: 17px;
      width: 1px;
      // background: #CFD5E8;
      background: rgba(255, 255, 255, 0.36);
      margin: 0 16px;
      box-sizing: border-box;
    }
  }
  .navbar_inner {
    height: 56px;
    width: 1224px;
    margin: 0 auto;

    .logo_icon {
      width: 131px;
      // height: 25px;
      // width: 160px;
      // height: 46px;
    }

    .navbar_menu {
      flex: 1;
      padding-left: 80px;
      text-align: left;
      height: 100%;
      display: flex;

      .narbar_item {
        a {
          padding: 0 28px;
          font-size: 16px;
          height: 56px;
          line-height: 56px;
          // color: rgba(0,0,0,0.8);
          // color: rgba(255,255,255,1);
          display: inline-block;

          &:hover {
            color: #e1251b;
            font-weight: 600;
          }
        }

        .activeNav {
          // color: rgba(225,37,27,1);
          // background: linear-gradient(
          //   180deg,
          //   rgba(225, 37, 27, 0.95) 0%,
          //   rgba(255, 91, 61, 0.95) 100%
          // );
          border-bottom: 2px solid #fff;
          font-weight: 600;

          // &:hover {
          //   color: #fff;
          // }
          & + .active_line {
            background: rgba(225, 37, 27, 1);
          }
        }

        .active_line {
          width: 16px;
          height: 2px;
          background: transparent;
          margin: 0 auto;
          margin-top: -2px;
        }
      }
    }

    .hot-line-wrap {
      // width: 256px;
      .phone-label {
        font-size: 12px;
        // color: rgba(0,0,0,0.56);;
        color: #fff;
        margin-right: 4px;
      }
      .phone_icon {
        width: 20px;
        height: 20px;
      }
      .hot-line-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      .phone-value {
        font-size: 16px;
        // color: #E1251B;
        color: #fff;
        font-weight: 600;
      }
    }
  }
}

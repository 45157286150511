.rodate_all {
  width: 1224px;
  margin: 0 auto;
  .rodate {
    display: flex;
    .rodate_box,
    .front {
      width: 408px;
      height: 227px;
      opacity: 1;
    }
  }
  .rodate_box {
    position: relative;
    overflow: hidden;
    .front {
      position: absolute;
      top: 0px;
      left: 0px;
      backface-visibility: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .qrcode {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // z-index: 1;
      transform: translateX(50px);
     

      &.actived{
        opacity: 1;
        transition: all 0.6s;
        transform:translateX(0);
      }

      

      img {
        width: 100%;
      }
      .code {
        top: 55px;
        left: 40px;
        position: absolute;
        border-radius: 8px;
      }
    }
  }
  .rodate_box:first-child {
    img {
      border-radius: 12px 0px 0px 12px;
    }
  }
  .rodate_box:last-child {
    img {
      border-radius: 0px 12px 12px 0px;
    }
  }
  .cardProjection {
    height: 83px;
    width: 100%;
    img {
      width: 100%;
    }
  }
}

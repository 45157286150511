.optional-dialog {
	width: 900px;
	height: 100vh;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	padding-top: 20px;
	padding-left: 32px;
	padding-right: 32px;

	&_header {
		width: 100%;
		height: 48px;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		flex-shrink: 0;
		position: relative;
		.v-text-title {
			font-size: 16px;
			color: rgba(0, 0, 0, 0.85);
			font-weight: bold;
		}
		.v-tab-item {
			font-size: 16px;
			font-weight: normal;
			color: rgba(85, 85, 85, 1);
		}
		.v-img-close {
			width: 14px;
			height: 14px;
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;
		}
	}

	&_line {
		width: 100%;
		height: 2px;
		background-color: #E9E9E9;
		position: relative;
	}

	&_body {
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-sizing: border-box;
		flex-shrink: 0;
		overflow-y: scroll;
		padding-top: 1px;
		padding-bottom: 20px;
		margin-top: 24px;

	}

	.plan-detail {
		width: 100%;
		position: relative;
		box-sizing: border-box;
		background-color: white;

		.v-table {
			max-width: 100%;
			max-height: 700px;
			display: block;
			overflow: scroll;
			box-sizing: border-box;
			border-left: 1px solid #E8E8E8;
			//border-bottom: 1px solid #E8E8E8;
			position: relative;
			//border-radius: 8px;
		}

		.v-table-header {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;
			position: sticky;
			top: 0;
			z-index: 2;
		}

		.v-table-row-wrap {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;
		}

		.v-table-row-item {
			//width: 278px;
			min-width: 270px;
			flex: 1;
			height: 50px;
			background-color: #F6F7F8;
			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;
			padding-left: 12px;
			padding-right: 12px;
			flex-shrink: 0;
			border-top: 1px solid #E9E9E9;
			border-bottom: 1px solid #E9E9E9;
			border-right: 1px solid #E9E9E9;
			overflow: hidden;
		}

		.v-table-column {
			display: block;
			height: 100%;
			box-sizing: border-box;
			min-width: 270px;
			flex: 1;
		}

		.v-coverage-wrap {
			width: 100%;
			//width: 278px;
			//min-width: 270px;
			//flex: 1;
			height: 80px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;
			flex-shrink: 0;
			padding: 10px 12px;
			border-bottom: 1px solid #E9E9E9;
			border-right: 1px solid #E9E9E9;
			overflow: hidden;
			position: relative;
			&_left {
				flex: 1;
				//display: flex;
				//flex-direction: row;
				//flex-wrap: wrap;
				//align-items: center;
				flex-shrink: 0;
				box-sizing: border-box;
				padding-right: 20px;
				font-size: 14px;
				color: #333333;
				line-height: 22px;
				//overflow: hidden;
				position: relative;

				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&_right {
				font-size: 14px;
				color: #333333;
				font-weight: bold;
			}
			.v-popover {
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				right: 0;
				bottom: 40px;
			}
		}

	}

}

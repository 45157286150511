.baner-wrap {
  width: 100%;
  height: 670px;
  overflow: hidden;
  // background: url(../../../static/imgs/banner.png) #F9FAFA no-repeat;
  // background: url(../../../static/imgs/banner2.png) #F9FAFA no-repeat;
  // background: url(../../../static/imgs/banner3.png) #f9fafa no-repeat;
  background-size: 100% 100%;
  position: relative;

  display: flex; /* 使用flex布局使内容居中 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */

  .video-container {
    width: 100%;
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* 半透明黑色 */
    pointer-events: none; /* 使蒙层不影响点击事件 */
  }

  .baner-video {
    width: 100%;
    height: auto;
    // position: absolute;
    // z-index: 1;
  }
  .text {
    position: absolute;
    // top: 30%;
    // left: 21.5%;
    // width: 1224px;
    display: flex;
    justify-content: space-between;
    width: 1224px; /* 设置文本容器的宽度 */
    // z-index: 1; /* 确保文字在蒙层之上 */
    .text-left {
      width: 437px;

      &-title {
        display: block;
        margin-bottom: 24px;
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
      }

      &-content {
        display: block;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: normal;
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .text-center {
      width: 1px;
      height: 150px;
      border: 1px solid rgba(255, 255, 255, 0.56);
    }

    .text-right {
      &-data {
        display: flex; /* 或者 inline-flex，根据需要 */
        flex-wrap: nowrap; /* 防止元素换行 */
        margin-bottom: 32px;

        &-list {
          display: inline-block;

          .list-top {
            display: flex; // 设置为flex布局
            align-items: baseline;
            line-height: 64px;

            .number {
              font-size: 64px;
              font-family: JDZhengHT;
              font-weight: normal;
              color: rgba(255, 255, 255, 1);
            }

            .unit {
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 600;
              color: rgba(255, 255, 255, 1);
            }
          }

          .list-bottom {
            text-align: center;

            .introduce {
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: normal;
              color: rgba(255, 255, 255, 1);
            }
          }
        }

        &-list:not(:last-child) {
          margin-right: 71px;
        }
      }

      &-explain {
        text-align: center;

        &-title {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: normal;
          color: rgba(255, 255, 255, 0.64);
        }
      }
    }
  }

  .joy_icon {
    position: absolute;
    left: 51.8%;
    top: 32%;
    width: 490px;
    height: 437px;
  }

  .icon {
    position: absolute;
  }

  .icon1 {
    left: 47.1%;
    top: 47.2%;
    width: 71px;
    height: 74px;
  }

  .icon2 {
    right: 8.39%;
    top: 37.83%;
    width: 197px;
    height: 202px;
  }
  .icon3 {
    left: 53.23%;
    top: 15.68%;
    width: 87px;
    height: 84px;
  }

  .icon4 {
    left: 27.5%;
    top: 60.98%;
    width: 135px;
    height: 94px;
  }
  .icon5 {
    left: 0.68%;
    // top: 16.75%;
    top: 10.17%;
    width: 190px;
    height: 143px;
  }

  .icon6 {
    right: 4.48%;
    top: 26.09%;
    width: 86px;
    height: 69px;
  }
  .title {
    position: absolute;
    left: calc(50% - 612px);
    top: 34.26%;
    font-size: 62px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 87px;
    font-weight: 600;
  }
  .title_sub {
    position: absolute;
    left: calc(50% - 612px);
    font-size: 24px;
    color: rgba(0, 0, 0, 0.8);
    top: 47.3%;
    line-height: 33px;
    width: 224px;
  }

  @media screen and (max-width: 1600px) {
    .icon2 {
      right: 6.39%;
      top: 37.83%;
      width: 185px;
      height: 190px;
    }
  }
}

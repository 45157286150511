.order-insured {
	width: 100%;

	.v-template {
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		position: absolute;
		top: 17px;
		right: 40px;
		//z-index: 2;
		.v-action-item {
			font-size: 13px;
			font-weight: normal;
			color: rgba(84, 106, 175, 1);
			line-height: 20px;
			cursor: pointer;
			&:hover {
				color: #E1251B;
			}
		}
		.v-action-gap {
			width: 1px;
			height: 10px;
			background-color: rgba(187, 187, 187, 1);
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	&_list {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		box-sizing: border-box;
		flex-shrink: 0;
	}

	.list-item {
		width: 572px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		outline: 1px solid #dae0e8;
		padding: 6px 10px;
		margin-top: 20px;
		margin-right: 20px;
		border-radius: 6px;
		position: relative;
		&:nth-child(2n) {
			margin-right: 0;
		}
		&_left {
			display: flex;
			flex-direction: row;
			align-items: center;
			position: relative;
		}
		&_right {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.v-premium {
			font-size: 14px;
			color: #E1251B;
		}
		.text-relation {
			font-size: 10px;
			font-weight: bold;
			color: #E1251B;
			border: 1px solid #E1251B;
			padding-left: 2px;
			padding-right: 2px;
			border-radius: 4px;
			margin-right: 8px;
		}
		.text-name {
			font-size: 14px;
			font-weight: bold;
			color: rgba(0, 0, 0, 0.85);
			margin-right: 8px;
		}
		.text-desc {
			font-size: 14px;
			font-weight: normal;
			color: rgba(140, 140, 140, 1);
			margin-right: 8px;
		}
		.btn-edit {
			padding: 0;
			margin-left: 12px;
			color: rgba(84, 106, 175, 1);
			font-size: 13px;
		}
		.btn-remove {
			padding: 0;
			margin-left: 12px;
			color: rgba(84, 106, 175, 1);
			font-size: 13px;
		}
		&_error {
			font-size: 12px;
			color: #E1251B;
			position: absolute;
			left: 0;
			bottom: -20px;
			z-index: 2;
		}
	}

	.btn-add {
		width: 572px;
		height: 44px;
		margin-top: 20px;
		border-radius: 6px;
		color: #999999;
		border: 1px dashed #D9D9D9;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		.v-img-add {
			width: 16px;
			height: 16px;
			margin-right: 4px;
		}
		.v-text-add {
			font-size: 14px;
			font-weight: normal;
			color: rgba(153, 153, 153, 1);
			line-height: 22px;
		}
	}

}

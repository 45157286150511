.common-agreement {
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	overflow-y: scroll;
	padding-bottom: 20px;

	.v-title-alert {
		font-size: 14px;
		color: #333333;
		line-height: 20px;
		padding-left: 20px;
		padding-right: 20px;
		box-sizing: border-box;
		position: relative;
		margin-top: 20px;
	}
}

.company-info-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 24px;
  }

  .table_l_r {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: none;
    border-radius: 4px;
    margin-bottom: 40px;
    .table_item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      background: rgba(0, 0, 0, 0.02);
    }

    &_left {
      width: 180px;
      padding: 12px 16px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.56);
      height: 100%;
    }
    &_right {
      flex: 1;
      padding: 12px 16px;
      border-left: 1px solid rgba(0, 0, 0, 0.08);
      background-color: #fff;

      .sub_title {
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 8px;
      }

      .delay_block {
        margin-bottom: 24px;
      }

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
        line-height: 24px;
      }
    }
  }

  .sub_title {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .sub_title_desc {
    height: auto!important;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .title_3 {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .tag_block {
      width: 4px;
      height: 12px;
      background: rgba(225, 37, 27, 1);
      border-radius: 2px;
      margin-right: 8px;
    }

    .title_3_text {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
      margin-bottom: 0;
    }
  }

  .box_2 {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;

    .line {
      display: flex;
      align-items: center;
      height: 28px;

      .box_2_left {
        width: 140px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.56);
        margin-right: 16px;
      }

      .box_2_right {
        flex: 1;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
        span.num {
          color: #e1251b;
          font-weight: 600;
        }
        span.kuo {
          color: rgba(0, 0, 0, 0.56);
        }

        .blueBorder {
          color: #00408c;
          border-bottom: 1px solid #00408c;
        }

        .online_box {
          background-image: linear-gradient(188deg, #ff7768 0%, #e1251b 90%);
          border-radius: 12px;
          height: 24px;
          width: 112px;
          padding: 0 16px;
          color: #fff;
          line-height: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }

  .box_3 {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 40px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);
    a {
      color: rgba(0, 64, 140, 1);
      cursor: pointer;
    }
    // .hoverBox{
    //   color: rgba(0, 64, 140, 1);
    //   margin-left: 14px;
    //   cursor: pointer;
    //   position:relative;
    //   .hoverDetailClaim{
    //     position:absolute;
    //     top: -158px;
    //     left: -138px;
    //     width: 398px;
    //     height: 142px;
    //     background: #fff;
    //     padding: 14px;
    //     border: 1px solid rgba(0,0,0,0.02);
    //     border-radius: 5px;
    //     box-shadow:  0 2px 14px 0 rgba(0,0,0,0.12);
    //     .header{
    //       font-size: 9px;
    //       font-family: PingFang SC;
    //       font-weight: 600;
    //       color: rgba(0,0,0,0.8);
    //       margin-bottom: 6px;
    //     }
    //     .detail{
    //       font-size: 8px;
    //       font-family: PingFang SC;
    //       font-weight: normal;
    //       color: rgba(0,0,0,0.56);
    //     }
    //     &::after{
    //       content: '';
    //       display: block;
    //       width: 0;
    //       height: 0;
    //       border-top: 10px solid #fff; 
    //       border-right: 10px solid transparent;
    //       border-left: 10px solid transparent;
    //       border-bottom: 10px solid transparent;
    //       margin-top: 10px;
    //       margin-left: 170px;
    //     }
    //   }
    //   .hoverDetail{
    //     position:absolute;
    //     top: -178px;
    //     left: -138px;
    //     width: 390px;
    //     height: 161px;
    //     background: #fff;
    //     padding: 14px;
    //     border: 1px solid rgba(0,0,0,0.02);
    //     border-radius: 5px;
    //     box-shadow:  0 2px 14px 0 rgba(0,0,0,0.12);
    //     .header{
    //       font-size: 9px;
    //       font-family: PingFang SC;
    //       font-weight: 600;
    //       color: rgba(0,0,0,0.8);
    //       margin-bottom: 6px;
    //     }
    //     .detail{
    //       font-size: 8px;
    //       font-family: PingFang SC;
    //       font-weight: normal;
    //       color: rgba(0,0,0,0.56);
    //       margin-bottom: 10px;
    //     }
    //     &::after{
    //       content: '';
    //       display: block;
    //       width: 0;
    //       height: 0;
    //       border-top: 10px solid #fff; 
    //       border-right: 10px solid transparent;
    //       border-left: 10px solid transparent;
    //       border-bottom: 10px solid transparent;
    //       margin-top: 10px;
    //       margin-left: 170px;
    //     }
    //   }
    //   .hoverDetailNone{
    //     display: none;
    //   }
    // }
  }

  .box_4 {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    .box_4_header {
      background: rgba(0, 0, 0, 0.02);
      height: 48px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
      display: flex;
      .header_item {
        padding: 12px 16px;
        width: calc(100% / 3);
        font-size: 14px;
        box-sizing: border-box;
      }
    }

    .box_4_body {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      &:last-child {
        border-bottom: none;
      }
      .body_item {
        width: calc(100% / 3);
        font-size: 12px;
        color: rgba(0, 0, 0, 0.56);
      }
      .body_item1{
        height: 60px;
      }
      .w_2 {
        width: calc(100% / 3 * 2);
      }
    }
    div:nth-child(3){
      .body_item1{
        padding: 22px 16px;
      }
    }
    div:nth-child(6){
      .body_item1{
        padding: 22px 16px;
      }
    }
    div:nth-child(4){
      .body_item2{
        padding: 22px 16px;
      }
    }
    .body_item_border {
      border-left: 1px solid rgba(0, 0, 0, 0.08);
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }

    .border_item_right {
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
  .box_5{
    margin-bottom: 40px;
    margin-left: 16px;
    a {
      color: rgba(0, 64, 140, 1);
      cursor: pointer;
    }
  }
}

.ins-checkbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	position: relative;
	flex-shrink: 0;
	cursor: pointer;
	.v-checkbox-img {
		width: 16px;
		height: 16px;
	}
	&--disabled {
		cursor: not-allowed;
	}
}

.question-page-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 40px;
  }

  .qa_nav_wrap {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 40px;
    cursor: pointer;
    .qa_nav {
      margin-right: 40px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.56);
      padding-bottom: 12px;

      &.active {
        font-weight: 600;
        color: rgba(225, 37, 27, 1);
        border-bottom: 2px solid rgba(225, 37, 27, 1);
        margin-bottom: -1px;
      }
    }
  }

  .sub_box {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    .sub_title_item {
      background-image: linear-gradient(36deg, #ea281e 34%, #ff8a84 100%);
      border-radius: 18px 18px 18px 0;
      color: #fff;
      width: fit-content;
      padding: 0 20px;
      font-size: 18px;
      line-height: 36px;
      margin-bottom: 24px;
      font-weight: 600;
    }

    .qa_item {
      .qa_icon {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        text-align: center;
        line-height: 20px;
        color: #fff;
        margin-right: 16px;
      }
      .qa_q_wrap {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .qa_q_icon {
          background-image: linear-gradient(219deg, #e5e7ec 0%, #c6cbd4 44%);
        }
        .qa_q {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.8);
          line-height: 26px;
        }
      }

      .qa_ans_wrap {
        display: flex;
        .qa_a_icon {
          background-image: linear-gradient(36deg, #ea281e 34%, #ff8a84 100%);
        }
        .qa_ans {
          margin-bottom: 24px;
          padding-bottom: 24px;

          flex: 1;
        }

        .qa_border {
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        }

        .qa_p {
          font-size: 14px;
          line-height: 26px;
          color: rgba(0, 0, 0, 0.56);
        }
        .qa_h {
          font-size: 14px;
          line-height: 26px;
          color: rgba(0, 0, 0, 0.56);
          font-weight: 600;
        }
        .qa_p2 {
          font-size: 14px;
          line-height: 26px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
}

.marjor-info-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 36px;
  }

  .tab_wrap {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 24px;

    .tab_item {
      line-height: 24px;
      font-size: 16px;
      margin-right: 80px;
      color: rgba(0, 0, 0, 0.56);
      padding-bottom: 14px;
      cursor: pointer;

      &.active {
        color: #ea281e;
        font-weight: 600;
        border-bottom: 2px solid #ea281e;
      }
    }
  }

  .p_wrap {
    .year_title {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      line-height: 22px;
      // margin-bottom: 16px;
    }

    .year_item_wrap {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .line_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(240, 241, 242, 1);
      padding: 12px 0;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        .line_date,
        .line_title {
          color: rgba(0, 64, 140, 1);
        }
      }
      .line_title {
        color: rgba(0, 0, 0, 0.72);
      }

      .line_date {
        color: rgba(0, 0, 0, 0.4);
      }
      .line_img {
        img {
          width: 16px;
          height: 9px;
        }
      }
    }
  }
}

.order-container {
	width: 100%;
	min-height: 100vh;
	padding-top: 97px;
	box-sizing: border-box;
	position: relative;

	input {
		box-shadow: none !important;
		&:hover {
			border-color: #E1251B !important;
		}
		&:focus {
			border-color: #E1251B !important;
		}
	}

	.ant-picker {
		box-shadow: none;
		&:hover:not(.ant-picker-disabled) {
			border-color: #E1251B;
		}
	}

	.ant-picker-focused {
		border-color: #E1251B;
	}

	.ant-select-selector {
		box-shadow: none !important;
		border-color: #d9d9d9 !important;
		&:hover {
			border-color: #E1251B !important;
		}
		&:focus {
			border-color: #E1251B !important;
		}
	}

	.ant-select-focused {
		border-color: #E1251B !important;
		box-shadow: none !important;
	}

	.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-color: #E1251B !important;
		box-shadow: none !important;
	}

	.ant-radio-inner {
		border-color: #E1251B;
		&:hover {
			border-color: #E1251B;
		}
		&:after {
			background-color: #E1251B;
		}
	}

	.ant-radio {
		box-shadow: none;
		border-color: #E1251B;
		&:hover {
			border-color: #E1251B;
		}
		&:after {
			background-color: #E1251B;
		}
	}

	.ant-radio-checked {
		box-shadow: none;
		border-color: #E1251B;
		&:hover {
			border-color: #E1251B;
		}
		&:focus {
			border-color: #E1251B;
		}
	}

	.ant-radio-input {
		box-shadow: none;
		border-color: #E1251B;
		&:hover {
			border-color: #E1251B;
		}
		&:focus {
			border-color: #E1251B;
		}
	}

	.ant-checkbox-inner {
		box-shadow: none;
		&:hover {
			border-color: #E1251B;
		}
		&:focus {
			border-color: #E1251B;
		}
	}

}

.ant-select-dropdown {
	//background-color: #FFF8F8 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background-color: rgba(225, 37, 27, 0.1) !important;
	color: #E1251B !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
	border-color: #E1251B;
}

.ant-picker-today-btn {
	color: #E1251B;
}

.ant-cascader-menu-item:not(.ant-cascader-menu-item-disabled) {
	&:hover {
		background-color: #FFF8F8 !important;
	}
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
	background-color: rgba(225, 37, 27, 0.1) !important;
	color: #E1251B !important;
	&:hover {
		background-color: rgba(225, 37, 27, 0.1) !important;
	}
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	background-color: #E1251B !important;
}

.ant-picker-header-view button {
	&:hover {
		color: #E1251B !important;
	}
	&:focus {
		color: #E1251B !important;
	}
}

html {
	--antd-wave-shadow-color: #E1251B !important;
}

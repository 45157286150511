.ins-radio {
	min-width: 66px;
	display: flex;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	position: relative;
	flex-shrink: 0;
	cursor: pointer;
	.v-radio-img {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}
	.v-radio-text {
		font-size: 14px;
		font-weight: normal;
		color: #262626;
		line-height: 14px;
	}
	&--active {
		.v-radio-text {
			color: #E1251B;
		}
	}
	&--disabled {
		cursor: not-allowed;
		.v-radio-text {
			color: rgba(0, 0, 0, 0.25);
		}
	}
}

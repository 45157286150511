.detail-question {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: -24px;

	&-item-wrap {
		width: 700px;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-top: 30px;
		padding-bottom: 30px;
		border-bottom: 1px dashed #DDDDDD;
	}

	&-img-question {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		margin-top: -2px;
	}

	&-item-wrap-right {
		flex: 1;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		margin-left: 8px;
	}

	&-item {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	&-item-question {
		font-size: 14px;
		font-weight: bold;
		color: rgba(51, 51, 51, 1);
		line-height: 16px;
	}

	&-item-answer {
		width: 100%;
		box-sizing: border-box;
		margin-top: 10px;
		line-height: 22px;
		&-text {
			font-size: 14px;
			font-weight: normal;
			color: #666666;
		}
	}

}

.productList {
  width: 1224px;
  margin: 0 auto;
  .insure_kind {
    margin-top: 48px;
    margin-bottom: 24px;
    .kind_one {
      display: flex;
      align-items: center;
      .kind_name {
        width: 60px;
        font-size: 16px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.8);
        margin-right: 24px;
      }
      .kind_value {
        flex: 1;
      }
      .name_item {
        cursor: pointer;
        display: inline-block;
        min-width: 96px;
        height: 32px;
        padding: 0 12px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        font-size: 14px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.64);
        margin-right: 16px;
        text-align: center;
        line-height: 32px;
      }
      .name_item:hover {
        border: 1px solid rgba(225, 37, 27, 0.56);
        color: rgba(225, 37, 27, 1);
      }
      ._name_item {
        cursor: pointer;
        display: inline-block;
        min-width: 96px;
        padding: 0 12px;
        height: 32px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        margin-right: 16px;
        text-align: center;
        line-height: 32px;
        background: rgba(225, 37, 27, 1);
        border: 1px solid transparent;
      }
    }
    .kind_two {
      margin-top: 16px;
    }
  }
  .product_num {
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.56);
    line-height: 20px;
    margin-bottom: 24px;

    span {
      font-weight: 600;
      color: rgba(225, 37, 27, 1);
    }
  }
  .product_all {
    display: flex;
    justify-content: end;
  }
}

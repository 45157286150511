.feature-dialog {
	width: 900px;
	height: 100vh;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	padding-top: 20px;
	padding-left: 32px;
	padding-right: 32px;

	&_header {
		width: 100%;
		height: 48px;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		flex-shrink: 0;
		position: relative;
		.v-tab-item {
			min-width: 64px;
			height: 100%;
			margin-right: 40px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			font-size: 16px;
			font-weight: normal;
			color: rgba(85, 85, 85, 1);
			cursor: pointer;
			&:hover {
				color: #E1251B;
			}
		}
		.v-tab-item-active {
			font-weight: bold;
			color: #E1251B;
		}
		.v-img-close {
			width: 14px;
			height: 14px;
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;
		}
	}

	&_line {
		width: 100%;
		height: 2px;
		background-color: #E9E9E9;
		position: relative;
		.v-indicator {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 64px;
			background-color: rgba(225, 37, 27, 1);
			z-index: 2;
			transition: transform 200ms ease-in-out;
			//transform: translateX(104px);
		}
	}

	&_body {
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-sizing: border-box;
		flex-shrink: 0;
		overflow-y: scroll;
		padding-top: 1px;
		padding-bottom: 20px;
		margin-top: 24px;

		.v-feature-item {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			box-sizing: border-box;
			flex-shrink: 0;
		}

	}

}

.serve-promise-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 32px;
  }

  .table_l_r {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: none;
    border-radius: 4px;
    margin-bottom: 40px;
    .table_item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      background: rgba(0, 0, 0, 0.02);
    }

    &_left {
      width: 180px;
      padding: 12px 16px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.56);
      height: 100%;
    }
    &_right {
      flex: 1;
      padding: 12px 16px;
      border-left: 1px solid rgba(0, 0, 0, 0.08);
      background-color: #fff;

      .sub_title {
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 8px;
      }

      .delay_block {
        margin-bottom: 24px;
      }

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
        line-height: 24px;
      }
    }
  }

  .sub_title {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
    margin-top: 32px;
  }

  .sub_title_desc {
    line-height: 26px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);
    margin-bottom: 16px;
  }

  .sub_title_item {
    font-weight: 600;
    line-height: 26px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);
  }

  .mb_40 {
    margin-bottom: 40px;
  }

  .sub_title_item_con {
    line-height: 26px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);
  }

  .sub_box {
    margin-bottom: 16px;
  }

  .title_3 {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .tag_block {
      width: 4px;
      height: 12px;
      background: rgba(225, 37, 27, 1);
      border-radius: 2px;
      margin-right: 8px;
    }

    .title_3_text {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
      margin-bottom: 0;
    }
  }

  .box_2 {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;

    .line {
      display: flex;
      align-items: center;
      height: 28px;

      .box_2_left {
        width: 140px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.56);
        margin-right: 16px;
      }

      .box_2_right {
        flex: 1;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
        span.num {
          color: #e1251b;
          font-weight: 600;
        }
        span.kuo {
          color: rgba(0, 0, 0, 0.56);
        }

        .blueBorder {
          color: #00408c;
          border-bottom: 1px solid #00408c;
        }

        .online_box {
          background-image: linear-gradient(188deg, #ff7768 0%, #e1251b 90%);
          border-radius: 12px;
          height: 24px;
          width: 112px;
          padding: 0 16px;
          color: #fff;
          line-height: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }

  .box_3 {
    margin-bottom: 40px;
    a {
      color: rgba(0, 64, 140, 1);
      cursor: pointer;
    }
  }

  .box_4 {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 40px;
    margin-top: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .box_4_header {
      background: rgba(0, 0, 0, 0.02);
      height: 48px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
      display: flex;
      .header_item {
        padding: 12px 16px;
        font-size: 14px;
        box-sizing: border-box;
      }
      .w_auto {
        flex: 1;
      }
    }

    .box_4_body {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      &:last-child {
        border-bottom: none;
      }
      .body_item {
        height: 120px;
        padding: 12px 16px;
        line-height: 18px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.56);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .w_2 {
        width: calc(100% / 3 * 2);
      }
      .w_auto {
        flex: 1;
      }
    }

    .body_item_border {
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }

    .border_item_left {
      border-left: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
}

.insure-notice-dialog {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	//background: linear-gradient(180deg, #FFEAEA 0%, #FFF7F5 34%, #FFFFFF 100%);
	background-image: linear-gradient(179deg, #FFEAEA 0%, #FFF7F5 25%, #FFFFFF 74%);
	//border: 1px solid rgba(0, 0, 0, 0.04);
	border-top: 1px solid rgba(225, 37, 27, 1);

	.v-notice-ctn {
		width: 1224px;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-sizing: border-box;
		padding-top: 36px;
		padding-bottom: 50px;
	}

	.top-text-notice {
		width: 100%;
		font-size: 20px;
		line-height: 26px;
		font-weight: bold;
		color: #000000;
	}

	.insure-confirm-desc {
		width: 100%;
		font-size: 16px;
		line-height: 24px;
		font-weight: normal;
		color: rgba(0, 0, 0, 0.8);
		margin-top: 16px;
		margin-bottom: 28px;
	}

	.insure-confirm-link {
		color: #546AAF;
		cursor: pointer;
	}

	.insure-confirm-button {
		height: 40px;
		font-size: 16px;
		border-radius: 8px;
		font-weight: bold;
		background-color: #E1251B;
		border: none;
		padding-left: 20px;
		padding-right: 20px;
		box-shadow: none;
		&:hover {
			background-color: #F36B64;
		}
		&:active {
			background-color: #B9140B;
		}
	}

}

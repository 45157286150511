.car_insureOne {
  padding: 100px 0;
  background: #f0f1f2;
}

.car_insure {
  padding: 100px 0 150px;
  background: #f0f1f2;
}

.car_insure_wrap {
  position: relative;
  width: 1224px;
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  padding: 31px 20px 20px 20px;
  margin: 0 auto;

  .carousel {
    display: flex !important;
    padding:20px;

    .carousel_left {
      flex: 1;

      &_title {
        font-size: 36px;
        font-family: PingFang SC;
        line-height: 36px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 31px;
      }

      &_introduce {
        margin-bottom: 12px;
        span {
          display: block;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.8);
        }
      }

      &_span {
        overflow: hidden;
        margin-bottom: 12px;

        span {
          float: left; /* 替换为 float: left */
          display: block;
          width: -webkit-max-content;
          width: -moz-max-content;
          width: max-content;
          padding: 6px 12px;
          margin: 0 8px 8px 0;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(225, 37, 27, 0.32);
          border-radius: 8px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 600;
          color: rgba(225, 37, 27, 1);
        }
      }

      &_insure {
        display: flex;
        padding: 6px;
        width: 300px;
        height: 130px;
        border-radius: 10px;
        background: #eff0f3;

        &_img {
          width: 118px;
          height: 118px;
          border-radius: 10px;
          padding: 6px;
          background: rgba(255, 255, 255, 1);
          display: flex;
          align-items: center;
          justify-content: center;

          .qrcode {
            position: relative;
            width: 100px;
            height: 100px;
            border: 1px solid rgba(0, 0, 0, 0.04);
            border-radius: 10px;
            box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08);
            padding: 20px;
            text-align: center;
            &-canvas {
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }

        &_font {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto auto;

          img {
            width: 24px;
            height: 19px;
            margin-right: 8px;
          }
          span {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.8);
          }
        }
      }
    }

    .carousel_right {
      width: 700px;
      height: 350px;

      img {
        width: 700px;
        height: 350px;
        border-radius: 12px;
      }
    }
  }

  .icon-left {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: -1.5%;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 1);
    border-radius: 50%;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(225, 37, 27, 1);

    &-icon {
      width: 12px;
      height: 8px;
      background-image: url("../../img/left_red_icon.png");
      background-size: 100% 100%;
      transition: transform 0.5s ease-in-out;
    }
  }
  .icon-right {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: -1.5%;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 1);
    border-radius: 50%;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(225, 37, 27, 1);

    &-icon {
      width: 12px;
      height: 8px;
      background-image: url("../../img/right_red_icon.png");
      background-size: 100% 100%;
      transition: transform 0.5s ease-in-out;
    }
  }
  .icon-left:hover {
    cursor: pointer;
    background: rgba(225, 37, 27, 1);
    border-radius: 50%;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.08);

    .icon-left-icon {
      width: 12px;
      height: 8px;
      background-image: url("../../img/left_white_icon.png");
      background-size: 100% 100%;
      transition: transform 0.5s ease-in-out;
      transform: translateX(-5px);
    }
  }

  .icon-right:hover {
    cursor: pointer;
    background: rgba(225, 37, 27, 1);
    border-radius: 50%;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.08);

    .icon-right-icon {
      width: 12px;
      height: 8px;
      background-image: url("../../img/right_white_icon.png");
      background-size: 100% 100%;
      transition: transform 0.5s ease-in-out;
      transform: translateX(5px);
    }
  }
}

/* 全局CSS文件中 */
.ant-carousel .slick-dots-bottom {
  position: absolute !important;
  bottom: -100px !important;
}

.ant-carousel .slick-dots li.slick-active {
  width: 20px !important;
  background-color: rgba(225, 37, 27, 1) !important; /* 红色 */
}

.ant-carousel .slick-dots li {
  width: 12px !important;
  border-radius: 2px !important;
  button {
    background-color: rgba(0, 0, 0, 0.12) !important; /* 红色 */
  }
}

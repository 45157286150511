.safety-info-wrap {
  margin: 0 auto;
  margin-top: 40px;
  width: 1224px;
  display: flex;

  .right {
    flex: 1;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    margin-left: 24px;
    padding: 32px;
  }
}

.dissent-form-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 24px;
  }

  .content_wrap {
    border-radius: 12px 12px 0 0;
    padding: 32px 0;
    width: 70%;

    .ant-input {
      border-radius: 7px;
    }
    .ant-input-group-addon,
    .ant-input-affix-wrapper {
      border-radius: 7px;
    }
    .ant-form-item:nth-of-type(3) .ant-form-item-explain-error{
      margin-left: 200px;
    }
    .ant-form-item-label > label {
      color: rgba(36, 48, 69, 0.72);
    }
    .ant-btn-lg {
      border-radius: 11px;
    }

    .code_btn {
      color: rgba(234, 40, 30, 1);
      border: 1px solid rgba(243, 134, 129, 1);
    }

    .btn_secondary_z {
      width: 120px;
      height: 40px;
      border-color: transparent;
      background: linear-gradient(
        90deg,
        rgba(234, 40, 30, 1) 0%,
        rgba(251, 74, 65, 1) 100%
      );
      border-radius: 11px;
      box-shadow: 0 4px 12px 0 rgba(251, 73, 65, 0.24);
      color: #fff;
    }

    .fee_btn {
      width: 160px;
      height: 40px;
      line-height: 40px;
      border: 1px solid rgba(225, 37, 27, 1);
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: rgba(225, 37, 27, 1);
      margin: 0 auto;
      cursor: pointer;
    }

    .query__form-container-slide-wrapper {
      width: 246px;
    }
  }
}

.complaint-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 24px;
  }

  .header_img {
    width: 100%;
    margin-bottom: 54px;
    img {
      width: 100%;
    }
  }

  .box2 {
    background: rgba(240, 240, 242, 0.56);
    border-radius: 12px;
    padding: 24px 32px;
    display: flex;

    .box2_item {
      width: calc(100% / 3);
      display: flex;
      flex-direction: column;

      &.box_border {
        border-left: 1px solid rgba(0, 0, 0, 0.08);
        border-right: 1px solid rgba(0, 0, 0, 0.08);
      }

      .box2_p1 {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.56);
      }
      .box2_p2 {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.56);
      }
      .img_code {
        width: 98px;
        height: 98px;
      }
      .box2_mb {
        margin-bottom: 16px;
      }
    }

    .box_center {
      align-items: center;
    }
    .box_last {
      padding-left: 24px;
    }
  }

  .sub_title {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .sub_title_desc {
    line-height: 26px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);
    margin-bottom: 16px;
  }
}

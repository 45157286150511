.ins-button {
	height: 40px;
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	font-size: 16px;
	flex-shrink: 0;
	box-sizing: border-box;
	cursor: pointer;
	line-height: 24px;
	padding-left: 24px;
	padding-right: 24px;

	&_v-text-normal {
		color: #FFFFFF;
	}

	&_v-text-plain {
		color: #595959;
	}

	&_enable-normal {
		background-color: #E1251B;
		border: 1px solid #E1251B;
		&:hover {
			background-color: #F36B64;
			border: 1px solid #F36B64;
		}
		&:active {
			background-color: #B9140B;
			border: 1px solid #B9140B;
		}
	}

	&_enable-plain {
		background-color: #FFFFFF;
		border: 1px solid #D9D9D9;
	}

	&_disabled-normal {
		cursor: not-allowed;
		background-color: rgba(225, 37, 27, 0.3);
	}

	&_disabled-plain {
		cursor: not-allowed;
		background-color: rgba(217, 217, 217, 0.3);
		color: rgba(0, 0, 0, 0.25);
	}
}

* {
  box-sizing: border-box;
}
.floating {
  z-index: 5;
  display: block;
  width: 32px;
  height: 208px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08);
  position: fixed;
  bottom: 100px;
  right: 0px;
  font-size: 12px;
  .service {
    height: 80px;
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &:hover .service_img {
      background: url('../../img/servicerAfter.svg');
      background-size: 100%;
      cursor: pointer;
    }

    .service_img {
      height: 20px;
      width: 20px;
      background: url('../../img/servicerBefore.svg') no-repeat;
      background-size: 100%;
    }
    .phone_img {
      height: 20px;
      width: 20px;
      background: url('../../img/iphoneBefore.svg') no-repeat;
      background-size: 100%;
    }
    .des {
      // width: 36px;
      // height: 40px;
      font-size: 12px;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.8);
      text-align: center;
      line-height: 18px;
    }
  }
  .service:hover {
    .des {
      color: rgba(225, 37, 27, 1);
    }
  }
  .phone {
    position: relative;
    &:hover .phone_img {
      cursor: pointer;
      background: url('../../img/iphoneAfter.svg');
      background-size: 100%;
    }
    .phone_store {
      display: none;
      position: absolute;
      top: -84px;
      left: -217px;
      width: 200px;
      height: 228px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(0, 0, 0, 0.04);
      border-radius: 8px;
      box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08);
      padding: 20px;
      text-align: center;
      .phone_img {
        width: 160px;
        height: 160px;
        margin-bottom: 10px;
        img {
          width: 100%;
        }
      }
      .text {
        font-size: 12px;
        color: rgba(225, 37, 27, 1);
        line-height: 20px;
      }
      .arrow {
        position: relative;
        bottom: 82px;
        left: 178px;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-left: 12px solid #fff;
        border-bottom: 16px solid transparent;
      }
    }
  }
  .phone:hover .phone_store {
    display: block;
  }
  .goUp {
    padding: 16px 10px;
    border-radius: 0 0 4px 4px;
    &:hover {
      cursor: pointer;
      background-image: linear-gradient(
        180deg,
        #e1251b 0%,
        #ec3127 71%,
        #ff5b31 100%
      );

      .goUpImg {
        background: url('../../img/topAfter.svg') no-repeat;
        background-size: 100%;
      }
    }
    .goUpImg {
      width: 12px;
      height: 16px;
      background: url('../../img/topBefore.svg') no-repeat;
      background-size: 100%;
    }
  }
}
.isFloating {
  display: block;
}

.news_preview_wrap {
  background: rgba(246, 247, 248, 1);
  padding-top: 97px;
  padding-bottom: 40px;
  width: 1224px;
  margin: 0 auto;
  .news_nav_wrap {
    padding-top: 24px;
    display: flex;
    align-items: center;

    img {
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }
    .news_nav_back {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.36);
      margin-right: 8px;
      cursor: pointer;
      &:hover,
      &:active {
        color: #e1251b;
      }
    }
    .news_nav_title {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.72);
    }
  }

  .news_header {
    text-align: center;
    padding-top: 56px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    h2 {
      font-size: 24px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      line-height: 34px;
      margin-bottom: 8px;
    }

    .date {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.36);
      line-height: 20px;
      margin-bottom: 16px;
    }
  }

  .news_body {
    p {
      line-height: 24px;
      color: rgba(0, 0, 0, 0.56);
      font-size: 14px;

      &:has(img) {
        text-align: center;
      }
    }

    video {
      max-width: 100%;
    }
  }

  .box2 {
    border-radius: 12px;
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.08);
    margin-top: 40px;

    .divi_line {
      width: 1px;
      background: rgba(0, 0, 0, 0.12);
    }

    .box2_item {
      width: 524px;
      display: flex;
      color: #fff;
      img {
        margin-right: 16px;
      }

      .header_wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .top {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.56);
          margin-bottom: 4px;
        }
        .bottom {
          font-weight: 600;
          color: rgba(225, 37, 27, 1);
        }
        .fs_20 {
          font-size: 20px;
        }
        .fs_14 {
          font-size: 14px;
        }
      }
    }
  }
}

.health-notice-dialog {
	width: 900px;
	height: 580px;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;

	&__footer {
		width: 100%;
		flex-shrink: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		box-sizing: border-box;
		padding: 20px;
		.v-footer-left {
		}
		.v-footer-right {
			margin-left: 20px;
		}
	}

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; 
}

.page-content-wrap {
  padding-top: 97px;
  padding-bottom: 40px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.list_product {
  min-height: calc(100vh - 97px);
  background: rgba(246, 247, 248, 1);
}
.toc-modal{
  .ant-modal-content{
    border-radius: 16px;
  };
  .ant-modal-header{
    border-radius: 16px;
    .ant-modal-title{
      font-size: 16px;
      color:#000 80%;
    }
  }
  .ant-modal-footer {
    border: none;
    padding: 0;
  }
}

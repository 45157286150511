.ins-loading {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99999;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	background-color: rgba(255, 255, 255, 0.5);
	.ant-spin {
		color: #E1251B;
	}
	.ant-spin-dot-item {
		background-color: #E1251B;
	}
}

.achina-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 40px;
  }

  .header_img {
    width: 100%;
    margin-bottom: 54px;
    img {
      width: 100%;
    }
  }

  .box2 {
    .box2_item {
      display: flex;

      .box2_p1 {
        width: 160px;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.8);
      }

      .box2_p2 {
        width: 40px;
        line-height: 26px;
      }
      .box2_p3 {
        flex: 1;
        line-height: 26px;
        color: rgba(0, 0, 0, 0.56);
      }
    }
  }

  .sub_title {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .sub_title_desc {
    line-height: 26px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);
    margin-bottom: 16px;
  }

  .fee_desc2 {
    font-size: 16px;
  }
}

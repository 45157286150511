.order-footer {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 20px;
	position: relative;

	&_left {
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		flex: 1;
		position: relative;

		.v-agree {
			position: absolute;
			left: 0;
			top: 2px;
			z-index: 2;
		}

		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: #E1251B;
			border-color: #E1251B;
		}

		.v-prompt {
			width: 124px;
			height: 48px;
			position: absolute;
			left: 8px;
			top: 0;
			z-index: 10;
			margin-top: -50px;
		}

		.v-agreement-wrap {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			box-sizing: border-box;
			flex: 1;
			padding-left: 24px;
		}

		.v-text {
			color: #262626;
			line-height: 20px;
			margin-right: 10px;
		}

		.v-link {
			font-size: 14px;
			color: #546AAF;
			line-height: 20px;
			margin-right: 10px;
			cursor: pointer;
		}
	}

	&_right {
		min-width: 500px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		box-sizing: border-box;

		.v-text {
			font-size: 16px;
			color: rgba(0, 0, 0, 0.8);
			margin-right: 10px;
		}

		.v-amount {
			font-size: 20px;
			color: #E1241B;
			font-weight: bold;
		}

		.btn-submit {
			min-width: 104px;
			height: 40px;
			margin-left: 20px;
		}

		.btn-back {
			min-width: 104px;
			height: 40px;
			margin-left: 20px;
		}
	}

}

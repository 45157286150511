.company-info-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 24px;
  }

  .sub_title {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .sub_title_desc {
    height: 24px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .box_3 {
    margin-bottom: 40px;
    a {
      color: rgba(0, 64, 140, 1);
      cursor: pointer;
    }
    .p_box {
      height: 24px;
    }
    .img_w100 {
      width: 100%;
    }
  }

  .box_4 {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 40px;
    .box_4_header {
      background: rgba(0, 0, 0, 0.02);
      height: 48px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
      display: flex;
      .header_item {
        padding: 12px 16px;
        width: calc(100% / 3);
        font-size: 14px;
        box-sizing: border-box;
      }
    }

    .box_4_body {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      &:last-child {
        border-bottom: none;
      }
      .body_item {
        line-height: 18px;
        padding: 12px 16px;
        line-height: 18px;
        width: calc(100% / 3);
        font-size: 12px;
        color: rgba(0, 0, 0, 0.56);
      }

      .w_2 {
        width: calc(100% / 3 * 2);
      }
    }

    .body_item_border {
      border-left: 1px solid rgba(0, 0, 0, 0.08);
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }

    .border_item_left {
      border-left: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
}

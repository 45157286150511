.production-list-wrap {
  margin: 0 auto;
  margin-top: 56px;
  width: 1224px;
  padding-top: 97px;

  .content {
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 32px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .input_wrap {
      position: relative;
      input {
        width: 500px;
        height: 40px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 20px;
        padding-left: 36px;
        &:hover,
        &:active {
          border: 1px solid rgba(0, 0, 0, 0.12);
        }
        &:focus-visible {
          outline: none;
        }
      }

      .icon_search {
        width: 14px;
        height: 14px;
        position: absolute;
        left: 13px;
        top: 13px;
      }

      button {
        width: 100px;
        height: 40px;
        background: rgba(225, 37, 27, 1);
        border-radius: 20px;
        border: none;
        position: absolute;
        right: 0;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        cursor: pointer;
      }
    }

    .title {
      height: 33px;
      line-height: 33px;
      font-size: 24px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .tab_wrap {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 24px;

    .tab_item {
      line-height: 24px;
      font-size: 16px;
      margin-right: 80px;
      color: rgba(0, 0, 0, 0.56);
      padding-bottom: 14px;
      cursor: pointer;

      &.active {
        color: #ea281e;
        font-weight: 600;
        border-bottom: 2px solid #ea281e;
      }
    }
  }

  .p_wrap {
    .year_title {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      line-height: 22px;
      // margin-bottom: 16px;
    }

    .year_item_wrap {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .line_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(240, 241, 242, 1);
      padding: 12px 0;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        .line_date,
        .line_title {
          color: rgba(0, 64, 140, 1);
        }
      }
      .line_title {
        color: rgba(0, 0, 0, 0.72);
      }

      .line_img {
        img {
          width: 16px;
          height: 9px;
        }
      }
    }
  }
}

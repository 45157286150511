.detail-content-item {
	width: 100%;
	display: block;
	position: relative;
	box-sizing: border-box;
	//text-align: center;

	&__img {
		//width: 100%;
		max-width: 700px;
	}
}

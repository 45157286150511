.inter-pro-wrap {
  padding-top: 97px;
  padding-bottom: 40px;
  margin: 0 auto;
  width: 1224px;

  .open-day-container {
    margin-top: 56px;
    text-align: center;
    .page-title {
      font-size: 24px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      line-height: 32px;
      margin-bottom: 24px;
      position: relative;
    }
    .page-sub-title{
      position:absolute;
      right: 0;
      font-size: 14px;
      color: #00408C;
      cursor: pointer;
    }
    .line {
      width: 100%;
      height: 2px;
      background-color: rgba(0, 0, 0, 0.04);
      margin-bottom: 24px;
    }

    table,
    td,
    th {
      font-size: 12px;
      vertical-align: middle;
      text-align: center;
      border: 1px solid rgba(0, 0, 0, 0.08);
      padding: 8px;
      line-height: 42px;
      color: rgba(0, 0, 0, 0.56);
    }
    th {
      height: 48px;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      background: #dae0e8;
    }
    td {
      line-height: 22px;
    }
    table {
      width: 100%;
      margin-bottom: 56px;
    }
  }
}

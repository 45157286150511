.hot-products-wrap{
  width: 100%;
  background: rgba(240,241,242,1);
  padding: 100px 0;
  .inner{
    width: 1224px;
    margin: 0 auto;
    .nav{
      height: 56px;
      margin-bottom: 56px;
      .title{
        font-size: 40px;
        font-weight: 600;
        color: rgba(0,0,0,0.8);
      }

      .more{
        font-size: 14px;
        color: rgba(225,37,27,1);
        cursor: pointer;
        &:hover{
          opacity: 0.72;
        }

        .more_icon{
          margin-left: 8px;
          width: 16px;
          height: 9px;
        }
      }
    }

    .products{
      .left{
        width: 180px;
        border-radius: 8px;
        background: #fff;
        .group_name{
          height: 222px;
          position: relative;
          border-radius: 8px;
          font-size: 24px;
          color: rgba(0,0,0,0.8);
          border-bottom: 1px solid rgba(240,241,242,1);
          cursor:pointer;
          &.active{
            background: linear-gradient(90deg, rgba(225,37,27,0.95) 0%,rgba(236,49,39,0.95) 50.94202628766485%,rgba(255,91,49,0.95) 88.24537880073153%,rgba(255,91,49,0.95) 100%);;
            font-size: 32px;
            font-weight: 600;
            color: rgba(255,255,255,1);
          }

          .group_name_icon{
            position: absolute;
            bottom: 0px;
            width: 120px;
            height: 61px;
          }

          .arrow_icon{
            position: absolute;
            right: -10px;
            width: 10px;
            height: 40px;
          }
        }
      }

      .right{
        width: 1004px;
        flex-wrap : wrap ;
        font-size: 0;
        align-content:space-between;
        .product_info_wrap{
          background: #fff;
          width: 490px;
          border-radius: 8px;
         
          &:hover:after {
            content: "";
            width: 490px;
            height: 24px;
            display: block;
            position: absolute;
            background: url(../../../static/imgs/hot_shadow.png) no-repeat;
            background-size: 100% 100%;
            z-index: 0;
          }
          &.add-margin-bottom{
            margin-bottom: 24px;
          }
          .product_img{
            width: 490px;
            height: 245px;
          }

          .product_info_desc{
            padding: 32px;
            height: 187px;
            h2{
              font-size: 24px;
              line-height: 33px;
              font-weight: 600;
              color: rgba(0,0,0,0.8);
              margin-bottom: 4px;
            }
            .sub_title{
              font-size: 16px;
              line-height: 22px;
              color: rgba(0,0,0,0.56);
              margin-bottom: 16px;
            }

            .price{
              height: 33px;
              font-size: 24px;
              font-weight: 600;
              color: rgba(225,37,27,1);
              margin-right: 4px;
            }

            .price_desc{
              height: 22px;
              font-size: 16px;
              color: rgba(0,0,0,0.8);
            
            }
          }
        }
      }
    }
  }
}
.order-policyholder {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	box-sizing: border-box;

	.v-item-wrap {
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		box-sizing: border-box;
		margin-top: 20px;
		margin-right: 20px;
		position: relative;

		//&:nth-last-child(1) {
		//	margin-right: 0;
		//}

		&_label {
			font-size: 14px;
			color: #262626;
			line-height: 20px;
		}

		&_select {
			width: 276px;
			height: 32px;
			border-radius: 6px;
			//border: 1px solid #E9E9E9;
			margin-top: 6px;
		}

		&_input {
			width: 276px;
			height: 32px;
			border-radius: 6px;
			//border: 1px solid #E9E9E9;
			margin-top: 6px;
		}

		&_error {
			font-size: 12px;
			color: #E1251B;
			position: absolute;
			left: 0;
			bottom: -20px;
			z-index: 2;
		}

		.v-upload-result-name {
			width: 170px;
			font-size: 12px;
			color: rgba(0, 0, 0, 0.65);
			line-height: 12px;
			position: absolute;
			top: 39px;
			left: 104px;
			z-index: 2;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.v-upload-result-img {
			width: 100px;
			height: 100px;
			position: absolute;
			right: -120px;
			bottom: 0;
			z-index: 2;
			border-radius: 6px;
			object-fit: cover;
		}

	}

	.v-img-upload {
		width: 96px;
		height: 32px;
		background-color: rgba(255, 248, 248, 1);
		border: 1px solid rgba(225, 37, 27, 1);
		border-radius: 6px;
		box-sizing: border-box;
		font-size: 14px;
		color: #E1251B;
		text-align: center;
		cursor: pointer;
		font-weight: normal;
		margin-top: 6px;
		&:hover {
			background-color: rgba(225, 37, 27, 1);
			color: white;
			font-weight: bold;
		}
		&:active {
			background-color: rgba(185, 20, 11, 1);
			color: white;
			font-weight: bold;
		}
	}

	.ant-radio-button-wrapper {
		min-width: 140px;
		text-align: center;
	}

	.ant-radio-wrapper {
		margin-right: 20px;
	}

}

.consult_wrap {
  padding: 100px 0;

  background: rgba(240, 241, 242, 0.36);
  .inner {
    margin: 0 auto;
    width: 1224px;
  }

  .title {
    line-height: 56px;
    font-size: 40px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 40px;
  }
  .card {
    width: 600px;
    height: 334px;
    position: relative;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid transparent;
    padding: 35px 40px;

    &:hover {
      border-color: rgba(225, 37, 27, 1);
    }

    &-title {
      img {
        width: 311px;
        height: 120px;
        margin-bottom: 20px;
      }

      &-code {
        display: flex;
        width: 225px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 11px 10px;

        img {
          width: 106px;
          height: 105px;
          margin-bottom: 0px;
        }

        &-rignt {
          margin: auto auto;

          .span1 {
            display: block;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.8);
          }

          .span2 {
            display: block;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #e1251b;
          }
        }
      }
    }

    .right_img {
      width: 277px;
      height: 383px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

.result-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;

	.v-result-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 156px;
		&_img {
			width: 28px;
			height: 28px;
			margin-right: 8px;
		}

		&_text {
			font-size: 24px;
			font-weight: bold;
			color: rgba(0, 0, 0, 0.85);
		}
	}

	.v-result-ctn {
		width: 1224px;
		min-height: 358px;
		background: rgba(255, 255, 255, 1);
		border-radius: 20px;
		position: relative;
		margin-top: 40px;
		padding: 24px 20px;
		box-sizing: border-box;

		.v-title-tag {
			position: absolute;
			top: 30px;
			left: 0;
			width: 3px;
			height: 12px;
			background-color: rgba(225, 37, 27, 1);
			//z-index: 2;
		}

		.v-text-prompt {
			font-size: 16px;
			font-weight: bold;
			//color: rgba(0, 0, 0, 0.85);
			//color: #E1251B;
			color: rgba(0, 0, 0, 0.85);
			line-height: 24px;
		}

		.v-text-alert {
			//color: #E1251B;
			color: rgba(0, 0, 0, 0.85);
		}

		.v-manual-ctn {
			width: 100%;
			min-height: 253px;
			background: linear-gradient(180deg, rgba(249, 250, 251, 1) 0%, rgba(255, 255, 255, 1) 100%);
			border-radius: 12px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 24px;
			padding-top: 30px;
		}

		.v-manual-step {
			min-height: 86px;
			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;
			margin-bottom: 40px;
			position: relative;
		}

		.v-step-line-done {
			width: 172px;
			height: 2px;
			background-color: rgba(225, 37, 27, 1);
			position: absolute;
			top: 13px;
			left: 38px;
			//z-index: 2;
		}

		.v-step-line-next {
			width: 172px;
			height: 2px;
			background-color: rgba(204, 204, 204, 1);
			position: absolute;
			top: 13px;
			right: 38px;
			//z-index: 2;
		}

		.v-manual-step-item {
			min-height: 86px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			position: relative;
			box-sizing: border-box;
			margin-right: 192px;
			&:last-child {
				margin-right: 0;
			}

			.v-step-done {
				border: 2px solid #E1251B;
				color: #E1251B;
			}

			.v-step-current {
				background-color: #E1251B;
				color: rgba(255, 255, 255, 1);
			}

			.v-step-next {
				border: 2px solid #979797;
				color: #979797;
			}

			.v-step-step {
				width: 28px;
				height: 28px;
				border-radius: 50%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				box-sizing: border-box;
				font-size: 16px;
				font-weight: bold;
				line-height: 16px;
			}
			.v-step-text {
				min-width: 120px;
				font-size: 16px;
				font-weight: normal;
				color: rgba(0, 0, 0, 0.85);
				line-height: 24px;
				position: absolute;
				top: 40px;
				text-align: center;
			}
			.v-step-desc {
				min-width: 220px;
				font-size: 13px;
				font-weight: normal;
				color: rgba(140, 140, 140, 1);
				line-height: 22px;
				position: absolute;
				top: 64px;
				text-align: center;
			}
		}

		.v-ctn {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;
			margin-top: 20px;
		}

		.v-img-qrcode-wrap {
			width: 180px;
			height: 180px;
			background-color: rgba(249, 250, 251, 1);
			border-radius: 10px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			flex-shrink: 0;
			margin-right: 24px;
			position: relative;
			.v-img-qrcode {
				width: 164px;
				height: 164px;
			}
			.v-text-qrcode {
				position: absolute;
				bottom: -30px;
				//z-index: 2;
				font-size: 14px;
				color: #E1251B;
			}
		}

		.v-policy {
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			padding-top: 4px;
			.v-row-item {
				display: flex;
				flex-direction: row;
				align-items: center;
				font-size: 14px;
				font-weight: normal;
				color: rgba(38, 38, 38, 1);
				line-height: 24px;
				margin-top: 8px;
			}
			.v-copy {
				color: rgba(84, 106, 175, 1);
				margin-left: 4px;
				cursor: pointer;
				&:hover {
					opacity: 0.72;
				}
			}
			.v-download {
				width: 146px;
				height: 40px;
				margin-top: 33px;
			}
			.v-download-policy {
				width: 146px;
				height: 40px;
				border-radius: 8px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				font-weight: bold;
				font-size: 16px;
				flex-shrink: 0;
				box-sizing: border-box;
				cursor: pointer;
				line-height: 24px;
				padding-left: 24px;
				padding-right: 24px;
				margin-top: 33px;
				color: white;
				background-color: #E1251B;
				border: 1px solid #E1251B;
				&:hover {
					background-color: #F36B64;
					border: 1px solid #F36B64;
				}
				&:active {
					background-color: #B9140B;
					border: 1px solid #B9140B;
				}
			}
		}

		.v-divider {
			width: 100%;
			height: 1px;
			background-color: rgba(233, 233, 233, 1);
			margin-top: 46px;
		}

		.v-footer {
			font-size: 14px;
			font-weight: normal;
			color: rgba(84, 106, 175, 1);
			line-height: 24px;
			margin-top: 24px;
			cursor: pointer;
			text-align: center;
			&:hover {
				opacity: 0.72;
			}
		}

	}

}

.concact-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 40px;
  }

  .content-wrap {
    display: flex;
    flex-wrap: wrap;
    .content-item {
      width: 50%;
      margin-bottom: 24px;
      &:nth-child(odd) {
        padding-right: 22px;
      }
      &:nth-child(even) {
        padding-left: 22px;
      }
      .sub_title {
        margin-bottom: 8px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        line-height: 24px;
      }

      .addre {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.56);
        margin-bottom: 8px;
      }
      .phone {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.56);
      }
    }
  }
}

.news_list_wrap {
  .banner_wrap {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }

    .font_wrap {
      position: absolute;
      top: 0;
      width: 1224px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      img {
        width: 373px;
        height: auto;
      }
    }
  }
  .news_list {
    width: 1224px;
    margin: 0 auto;
    margin-top: 56px;

    .news_item {
      display: flex;
      cursor: pointer;

      .img_wrap {
        width: 250px;
        height: 125px;
        margin-right: 32px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }

      .r_box {
        flex: 1;
        .title {
          font-size: 24px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.8);
          &:hover {
            color: #e1251b;
          }
        }
        .date {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.36);
          margin-bottom: 8px;
        }
        .news_desc {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.56);
          line-height: 24px;
          &:hover {
            color: rgba(0, 0, 0, 0.72);
          }
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.08);
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

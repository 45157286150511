.agreement-list-dialog {
	width: 900px;
	height: 580px;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;

	&__body {
		width: 100%;
		flex: 1;
		box-sizing: border-box;
		padding-bottom: 20px;
		display: flex;
		flex-direction: column;
		overflow-y: auto;

		&-title {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			font-size: 16px;
			font-weight: bold;
			color: rgba(46, 45, 45, 1);
			box-sizing: border-box;
			padding: 20px;
			//margin-top: 10px;
			//&:first-child {
			//	margin-top: 0;
			//}
		}

		&-item {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;
			padding: 20px;
			cursor: pointer;
			border-bottom: 1px solid #dae0e8;
			&-name {
				font-size: 14px;
				font-weight: normal;
				color: rgba(132, 132, 132, 1);
				line-height: 22px;
				box-sizing: border-box;
			}
			&-arrow {
				margin-left: 20px;
				width: 6px;
				height: 12px;
				flex-shrink: 0;
			}
		}

	}

}

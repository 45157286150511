.plan-detail {
	width: 100%;
	position: relative;
	box-sizing: border-box;
	background-color: white;

	.v-table {
		max-width: 100%;
		max-height: 700px;
		display: flex;
		flex-direction: column;
		align-content: flex-start;
		overflow: auto;
		box-sizing: border-box;
		border-left: 1px solid #E8E8E8;
		//border-bottom: 1px solid #E8E8E8;
		position: relative;
		//border-radius: 8px;
	}

	.v-table-header {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		position: sticky;
		top: 0;
		z-index: 2;
	}

	.v-table-row-wrap {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		align-content: flex-start;
		box-sizing: border-box;
	}

	.v-table-row-item {
		//width: 278px;
		min-width: 270px;
		flex: 1;
		height: 50px;
		background-color: #F6F7F8;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		padding-left: 12px;
		padding-right: 12px;
		flex-shrink: 0;
		border-top: 1px solid #E9E9E9;
		border-bottom: 1px solid #E9E9E9;
		border-right: 1px solid #E9E9E9;
		overflow: hidden;
	}

	.v-table-column {
		flex: 1;
		min-width: 270px;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-content: flex-start;
		box-sizing: border-box;
	}

	.v-table-column-item {
		width: 100%;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		flex-shrink: 0;
	}

	.v-coverage-header {
		background-color: rgba(240, 240, 241, 1);
	}

	.v-text-strong {
		font-weight: bold;
	}

	.v-coverage-wrap {
		width: 100%;
		//width: 278px;
		//min-width: 270px;
		//flex: 1;
		height: 80px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		flex-shrink: 0;
		padding: 10px 12px;
		border-bottom: 1px solid #E9E9E9;
		border-right: 1px solid #E9E9E9;
		overflow: hidden;
		position: relative;
		&_left {
			flex: 1;
			//display: flex;
			//flex-direction: row;
			//flex-wrap: wrap;
			//align-items: center;
			flex-shrink: 0;
			box-sizing: border-box;
			padding-right: 20px;
			font-size: 14px;
			color: #333333;
			line-height: 22px;
			//overflow: hidden;
			position: relative;

			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&_right {
			font-size: 14px;
			//color: #333333;
			color: #000000;
			//font-weight: bold;
		}
		.v-popover {
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			right: 0;
			bottom: 40px;
		}
	}

}

.ant-popover-arrow-content {
	--antd-arrow-background-color: rgba(0, 0, 0, 0.65);
}

.ant-popover-inner {
	max-width: 270px;
	border-radius: 8px;
	background-color: rgba(0, 0, 0, 0.65);
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 12px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.02);
	box-sizing: border-box;
	word-break: break-all;
}

.ant-popover-title {
	color: white;
	padding-left: 8px;
}

.ant-popover-inner-content {
	color: white;
	font-size: 12px;
	line-height: 18px;
	//padding: 8px 16px;
	padding: 8px;
}

.bxscDetail_wrap{
  width: 1224px;
  margin: 0 auto;
  padding-top: 97px;
  .news_nav_wrap {
    padding-top: 24px;
    display: flex;
    align-items: center;

    img {
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }
    .news_nav_back {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.36);
      margin-right: 8px;
      cursor: pointer;
      &:hover,
      &:active {
        color: #e1251b;
      }
    }
    .news_nav_title {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.72);
    }
  }
  .bxscDetail_box{
     width: 1224px;
     background: rgba(236,240,244,1);
     border-radius: 24px 24px 0 0;
     margin-top: 24px;
     
    
    .content{
      padding: 0 40px 40px;
      box-sizing: border-box;
      .title{
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 600;
        color: rgba(0,0,0,0.8);
        margin: 32px 0 20px;
        .tag{
          display: inline-block;
          width: 96px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: linear-gradient(144.22deg, rgba(234,40,30,1) 0%,rgba(255,138,132,1) 100%);
          border-radius: 16px 16px 0 16px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 600;
          color: rgba(255,255,255,1);
          margin-right: 12px;
        }
        .tag1{
          width: 304px;
        }
      }
      .detail{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: normal;
        color: rgba(0,0,0,0.56);
      }
      .imgBox{
        width: 1146px;
        height: 278px;
        img{
          max-width: 100%;
        }
      }
    }
    .downLoad{
      padding: 0 0 40px 40px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: normal;
      color: rgba(0,64,140,1);
      cursor: pointer;
    }
  }
  .mainTitle{
    display: flex;
    align-items: center;
    width: 1224px;
    height: 56px;
    line-height: 56px;
    background: linear-gradient(180deg, rgba(233,237,242,1) 0%,rgba(221,228,237,1) 100%);
    border-radius: 24px 24px 0 0;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 600;
    color: rgba(0,0,0,0.8);
    padding-left: 40px;
    .mainTitleIcon{
      width: 4px;
      height: 16px;
      margin-right: 12px;
    }
  }
  .mainTitle1{
    border-radius: 0;
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%,rgba(221,228,237,1) 100%);
    margin-bottom: 45px;
  }
  .marketo_box{
    background: #fff;
    width: 1224px;
    height: 648px;
    // background: linear-gradient(0deg, rgba(255,255,255,1) 0%,rgba(221,228,237,1) 100%);
    border-radius: 0 0 24px 24px;
    #mktoForm_41378{
      width: 610px!important;
      margin: 0 auto;
      .mktoForm .mktoLabel{
        color:#243045!important;
        font-size: 14px!important;
      }
      input, #onlinequoteProspectSector,#Country__c,#LineOfBusiness__c{
        width: 500px!important;
        border: 1px solid #243045!important;
      }
      .mktoCheckboxList{
        width: 0!important;
      }
      #LbloptInValidated{
        margin-left: 30px!important;
      }
      #optInValidated{
        width: 20px!important;
        position: absolute!important;
        left: 0px!important;
      }
      .mktoButton{
        width: 120px!important;
        height: 40px!important;
        background: linear-gradient(90deg, rgba(234,40,30,1) 0%,rgba(251,74,65,1) 100%)!important;
        border-radius: 11px!important;
        box-shadow:  0 4px 12px 0 rgba(251,73,65,0.24)!important;
        border: none!important;
        margin-left: 125px!important;
      }
      a{
        color: #00408C!important;
      }
    }
    
  }
}
// body{
//   background-color: rgba(240,241,242,0.36);//不要删
// }
.contact_main {
  width: 100%;
  height: 450px;
  background: url(../../img/footerBg.png) rgba(240, 241, 242, 0.36) no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // padding-bottom: 70px;
  padding-bottom: 55px;
  .contact_top {
    background-color: rgba(240, 241, 242, 0.4);
    height: 189px;
    width: 100%;
    z-index: -1;
  }
  .contact_center {
    width: 100%;
    background-color: rgba(240, 241, 242, 0.5);
    .contact {
      width: 1224px;
      // height: 211px;
      height: 235px;
      padding: 0;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .contactUs {
        text-align: left;
        a:hover {
          color: rgba(225, 37, 27, 1);
        }
        .contact_tit {
          font-weight: 600;
          font-size: 16px;
          color: #000000;
        }
        .contact_info {
          margin-top: 14px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.56);
          font-weight: normal;
          .phone {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          p {
            line-height: 34px;
          }

          span {
            font-size: 20px;
            color: rgba(225, 37, 27, 1);
            font-weight: 600;
            line-height: 28px;
          }

          &.flex-wrap {
            display: flex;
            flex-wrap: wrap;
            width: 350px;
            p:nth-child(odd) {
              width: 60%;
            }
            p:nth-child(even) {
              width: 40%;
            }
          }
        }
      }
      // .info_contact {
      //   width: 221px;
      // }

      .info_line {
        width: 1px;
        background: rgba(0, 0, 0, 0.12);
        height: 100%;
        margin-left: 22px;
        margin-right: 22px;
      }
      .info_dis {
        width: 333px;
        border-left: 1px solid rgba(0, 0, 0, 0.12);
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        padding-left: 30px;
        a:hover {
          color: rgba(225, 37, 27, 1);
        }
      }
      .info_jdinsurance {
        // width: 312px;
        width: 335px;
        // padding-left: 69px;
        .code_insurance {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          .code {
            // width: 135px;
            width: 154px;
            .info_code {
              height: 154px;
              display: flex;
              background-color: #fff;
              position: relative;
              img {
                width: 100%;
                border-radius: 8px;
              }
            }
            .store {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.8);
              margin-top: 12px;
              text-align: center;
            }
          }
          .mask_yayer {
            opacity: 0.16;
          }
        }
      }
      a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.56);
      }
    }
  }
}

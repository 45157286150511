.company-info-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 24px;
  }

  .table_l_r {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: none;
    border-radius: 4px;
    margin-bottom: 40px;
    .table_item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      background: rgba(0, 0, 0, 0.02);
    }

    &_left {
      width: 200px;
      padding: 12px 16px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.56);
      height: 100%;
    }
    &_right {
      flex: 1;
      padding: 12px 16px;
      border-left: 1px solid rgba(0, 0, 0, 0.08);
      background-color: #fff;

      span {
        margin-right: 24px;
      }

      label {
        color: rgba(0, 64, 140, 1);
        cursor: pointer;
        &:hover {
          color: #40a9ff;
        }
      }

      .sub_title {
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 8px;
      }

      .delay_block {
        margin-bottom: 24px;
      }

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
        line-height: 24px;
      }

      .line {
        display: flex;
        line-height: 24px;
        margin-bottom: 6px;
        color: rgba(0, 0, 0, 0.8);
        .label {
          width: 100px;
        }
        .value {
          flex: 1;
          & > span {
            position: relative;
            cursor: pointer;
          }

          & > span:hover {
            .hover_img {
              display: block;
            }
          }

          .hover_img {
            z-index: 1;
            background: #fff;
            position: absolute;
            top: 20px;
            left: 0;
            width: 160px;
            height: 160px;
            display: none;
            border: 1px solid rgba(0, 0, 0, 0.04);
            border-radius: 8px;
            box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08);
            img {
              width: 100%;
              height: 100%;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }

  .sub_title {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .sub_title_desc {
    height: 24px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .title_3 {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .tag_block {
      width: 4px;
      height: 12px;
      background: rgba(225, 37, 27, 1);
      border-radius: 2px;
      margin-right: 8px;
    }

    .title_3_text {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
      margin-bottom: 0;
    }
  }

  .box_2 {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;

    .line {
      display: flex;
      align-items: center;
      height: 28px;

      .box_2_left {
        width: 140px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.56);
        margin-right: 16px;
      }
    }
  }

  .box_3 {
    margin-bottom: 40px;
    a {
      color: rgba(0, 64, 140, 1);
      cursor: pointer;
    }
  }
}

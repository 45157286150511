a {
  text-decoration: none;
}
.footer {
  width: 100%;
  // height: 243px;
  height: 84px;
  background: rgba(31, 32, 33, 1);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.56);
  font-weight: Regular;
  text-align: left;
  line-height: 20px;
  .footer_info {
    width: 1224px;
    margin: 0 auto;
    padding: 56px 0 24px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    a {
      color: rgba(255, 255, 255, 0.56);
    }
    a:hover {
      color: #fff;
    }
  }
  .footer_last {
    width: 1224px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    a:hover {
      color: #fff;
    }
    .copyRight {
      display: flex;
      align-items: center;
      .badge {
        margin-left: 20px;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        img {
          width: 100%;
        }
      }
      a {
        color: rgba(255, 255, 255, 0.56);
        display: block;
      }
      a:hover {
        color: #fff;
      }
    }
    span {
      a {
        color: rgba(255, 255, 255, 0.56);
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.fee-back-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 24px;
  }

  .content_wrap {
    background: linear-gradient(
      219.02deg,
      rgba(229, 231, 236, 0.24) 0%,
      rgba(198, 203, 212, 0.24) 100%
    );
    border-radius: 12px 12px 0 0;
    padding: 32px;

    .header_img {
      width: 100%;
      margin-bottom: 24px;
      img {
        width: 100%;
      }
    }

    .fee_desc {
      font-size: 16px;
      font-weight: 600;
      color: rgba(225, 37, 27, 1);
      margin-bottom: 24px;
    }

    .fee_line {
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
      margin-bottom: 24px;
    }
    .fee_desc_2 {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
      margin-bottom: 40px;
    }
    .fee_btn {
      width: 160px;
      height: 40px;
      line-height: 40px;
      border: 1px solid rgba(225, 37, 27, 1);
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: rgba(225, 37, 27, 1);
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .box2 {
    background: linear-gradient(
      144.22deg,
      rgba(234, 40, 30, 1) 0%,
      rgba(255, 138, 132, 1) 100%
    );
    border-radius: 0 0 12px 12px;
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;

    .divi_line {
      width: 1px;
      background: rgba(255, 255, 255, 0.32);
    }

    .box2_item {
      width: 380px;
      display: flex;
      color: #fff;
      img {
        margin-right: 16px;
      }

      .header_wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .top {
          font-size: 14px;
          margin-bottom: 4px;
        }
        .bottom {
          font-weight: 600;
          a {
            color: #fff;
            &:hover {
              color: rgba(255, 255, 255, 0.8);
            }
          }
        }
        .fs_20 {
          font-size: 20px;
        }
        .fs_14 {
          font-size: 14px;
        }
      }
    }
  }

  .sub_title {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .sub_title_desc {
    line-height: 26px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);
    margin-bottom: 16px;
  }
}

.insured-dialog {
	width: 626px;
	max-height: 580px;
	display: flex;
	flex-direction: column;
	position: relative;
	box-sizing: border-box;
	background-color: white;
	border-radius: 12px;

	.item-header {
		width: 100%;
		height: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		flex-shrink: 0;
		border-bottom: 1px solid #dae0e8;
		padding-left: 30px;
		padding-right: 30px;
		.text-title {
			font-size: 14px;
			color: #333333;
		}
		.img-close {
			width: 30px;
			height: 30px;
			cursor: pointer;
		}
	}

	&_body {
		width: 100%;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		flex: 1;
		overflow-y: scroll;
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 30px;
	}

	.item-wrap {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		flex-shrink: 0;
		margin-top: 20px;
		position: relative;

		.v-popover-wrap {
			width: 100px;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			//background-color: black;
			z-index: 2;
			cursor: pointer;
		}

		&_label {
			width: 100px;
			font-size: 12px;
			color: #333333;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
			margin-right: 20px;
		}

		&_select {
			width: 190px;
			height: 32px;
			margin-right: 10px;
			border-radius: 6px;
		}

		&_input {
			flex: 1;
			height: 32px;
			border-radius: 6px;
		}

		&_error {
			font-size: 12px;
			color: #E1251B;
			position: absolute;
			left: 0;
			bottom: -20px;
			z-index: 2;
		}

	}

	.item-footer {
		width: 100%;
		display: flex;
		flex-direction: row;
		box-sizing: border-box;
		flex-shrink: 0;
		align-items: center;
		justify-content: flex-end;
		padding-right: 30px;
		padding-bottom: 20px;
		.btn-submit {
			width: 100px;
		}
		.btn-cancel {
			width: 100px;
			margin-left: 20px;
		}
	}

	.v-relation-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
	}

	.v-relation-item {
		min-width: 92px;
		height: 32px;
		line-height: 24px;
		border: 1px solid #E9E9E9;
		background-color: transparent;
		border-radius: 6px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		margin-right: 8px;
		cursor: pointer;
		font-size: 14px;
		color: rgba(0, 0, 0, 0.64);
		font-weight: normal;
		&:hover {
			border: 1px solid rgba(225, 37, 27, 0.56);
			color: #E1251B;
		}
	}
	.v-relation-item-active {
		border: 1px solid #E1251B;
		background-color: #E1251B;
		color: white;
		font-weight: bold;
		&:hover {
			color: white;
		}
	}

	.v-img-result {
		width: 452px;
		height: 48px;
		border-radius: 6px;
		object-fit: cover;
		cursor: pointer;
	}

	.v-img-upload {
		width: 452px;
		height: 48px;
		border-radius: 6px;
		border: 1px dashed #D9D9D9;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		font-size: 14px;
		font-weight: normal;
		color: rgba(153, 153, 153, 1);
		cursor: pointer;
		.v-img-add {
			width: 16px;
			height: 16px;
			margin-right: 4px;
		}
		.v-text-add {
			font-size: 14px;
			font-weight: normal;
			color: rgba(153, 153, 153, 1);
			line-height: 22px;
		}
	}

	.ant-upload-picture-card-wrapper {
		width: 210px;
		height: 100px;
		box-sizing: border-box;
	}

	.ant-upload.ant-upload-select-picture-card {
		width: 200px;
		height: 100px;
		border-radius: 6px;
		box-sizing: border-box;
		margin-right: 10px;
		margin-bottom: 0;
	}

	.ant-radio-wrapper {
		margin-right: 20px;
	}

}

.tip-popover {
	max-width: 400px;
	max-height: 300px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow-y: auto;

	.v-list-item {
		width: 100%;
		display: block;
		margin-top: 10px;
		&:first-child {
			margin-top: 0;
		}
	}

	.v-text-title {
		width: 100%;
		display: block;
		font-size: 14px;
		color: white;
		box-sizing: border-box;
		font-weight: bold;
		line-height: 20px;
	}

	.v-text-sub {
		width: 100%;
		display: block;
		font-size: 12px;
		font-weight: normal;
		//color: rgba(102, 102, 102, 1);
		color: white;
		line-height: 20px;
		box-sizing: border-box;
		margin-top: 4px;
	}
}

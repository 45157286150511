.short-insure-info-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 40px;
  }

  .tab_wrap {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 36px;

    .tab_item {
      line-height: 24px;
      font-size: 16px;
      margin-right: 80px;
      color: rgba(0, 0, 0, 0.56);
      padding-bottom: 14px;
      cursor: pointer;

      &.active {
        color: #ea281e;
        font-weight: 600;
        border-bottom: 2px solid #ea281e;
        margin-bottom: -1px;
      }
    }
  }

  .p_wrap {
    .p_item {
      display: flex;
      margin-bottom: 24px;
      color: rgba(0, 0, 0, 0.72);
      font-size: 14px;

      .p_index {
        width: 24px;
        margin-right: 8px;
        line-height: 26px;
      }

      .p_content {
        flex: 1;
        line-height: 26px;
      }
    }
    .line_item {
      background: linear-gradient(
        270deg,
        rgba(251, 251, 252, 1) 0%,
        rgba(245, 247, 249, 1) 100%
      );
      display: flex;
      justify-content: space-between;
      padding: 16px;
      font-size: 14px;
      .line_title {
        color: rgba(0, 0, 0, 0.72);
        span {
          color: #e1251b;
        }
      }

      .line_date {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    .word_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(240, 241, 242, 1);
      padding-bottom: 12px;
      padding-top: 12px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        .word_title,
        .word_date {
          color: rgba(0, 64, 140, 1);
        }
      }
      .word_title {
        color: rgba(0, 0, 0, 0.72);
      }

      .word_date {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.ins-dialog-header {
	width: 100%;
	height: 50px;
	position: relative;
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	border-bottom: 1px solid #dae0e8;
	padding-left: 24px;
	padding-right: 16px;
	&-text {
		font-size: 16px;
		color: rgba(0, 0, 0, 0.85);
		font-weight: bold;
	}
	&-close {
		width: 14px;
		height: 14px;
		cursor: pointer;
	}

}

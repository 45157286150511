.test-container {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: 120px 30px 30px;
	margin: 0 auto;
	.test-text {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		box-sizing: border-box;
		word-break: break-word;
		font-size: 24px;
		margin-top: 10px;
	}

}

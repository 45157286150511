.alianz-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 40px;
  }

  .sub_title {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 16px;
  }

  .mr_2b {
    margin-bottom: 40px;
  }

  .sub_title_desc {
    line-height: 26px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);
    margin-bottom: 16px;
  }
}

.product_item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .list_left {
    width: 465px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 24px 20px 24px 20px;
    margin-bottom: 24px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;

    &:hover {
      box-shadow: 0 24px 56px 0 rgba(0, 0, 0, 0.08);
      transition: all 0.2s;
    }

    &_img {
      border-radius: 8px;
      img {
        width: 425px;
        height: 213px;
        border-radius: 8px;
      }
    }

    .list_bigTitle {
      text-align: left;
      font-size: 20px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      margin: 24px 0 12px 0;
      white-space: nowrap; /* 确保文本在一行内显示 */
      overflow: hidden; /* 隐藏超出容器的文本 */
      text-overflow: ellipsis; /* 添加省略号 */
    }
    .list_desc {
      flex: 1; /* 允许.list_desc伸缩以填充可用空间 */
      margin-top: 12px;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.56);
      line-height: 24px;
      .desc_item {
        display: flex;
        align-items: flex-start; /* 确保内容在第一行对齐 */
        margin-bottom: 4px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.56);
        .desc_img {
          margin-top: 4px;
          margin-right: 9px;
          line-height: 11px;
        }
      }
      .ellipsis {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
    .list_do {
      display: flex;
      justify-content: space-between;
      margin-top: auto; /* 推到底部 */
      display: flex;
      justify-content: space-between;

      .list_price {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 600;
        color: rgba(225, 37, 27, 1);
        line-height: 40px;

        .price_start {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: normal;
          color: rgba(0, 0, 0, 0.8);
          line-height: 34px;
          margin-left: 4px;
        }
      }

      .jumpBtn {
        // width: 120px;
        height: 40px;
        background: linear-gradient(
          132deg,
          rgba(255, 54, 44, 0.8) 0%,
          rgba(255, 176, 132, 0.8) 100%
        );
        border: none;
        border-radius: 8px;
        padding: 8px 16px;
        position: relative;

        &:hover {
          z-index: 1000;
          background: linear-gradient(
            132deg,
            rgba(255, 54, 44, 1) 0%,
            rgba(255, 176, 132, 1) 100%
          );
        }

        .title {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 600;
          color: rgba(255, 255, 255, 1);
          line-height: 16px;
        }
      }
    }

    .list_do_2 {
      display: flex;
      justify-content: flex-end;
    }
  }

  .list_mesg {
    width: 100%;
  }

  .one_left {
    height: 280px;
    width: 950px;
  }
}

.agreement-step-confirm-dialog {
	width: 900px;
	height: 580px;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	box-sizing: border-box;

	&__body {
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
	}

	&__footer {
		width: 100%;
		flex-shrink: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		box-sizing: border-box;
		padding: 20px;
		position: relative;
		.v-footer-btn {
			min-width: 224px;
			height: 40px;
		}
		.v-footer-step {
			font-size: 14px;
			color: rgba(89, 89, 89, 1);
			font-weight: bold;
			margin-right: 20px;
		}
	}

}

.detail-html {
	max-width: 100% !important;
	box-sizing: border-box;
	white-space: normal;
	word-break: break-word;
	display: inline-block;
	table {
		max-width: 100% !important;
		box-sizing: border-box;
		word-break: break-word;
	}
	colgroup {
		max-width: 100% !important;
		box-sizing: border-box;
		word-break: break-word;
	}
	tbody {
		max-width: 100% !important;
		box-sizing: border-box;
		word-break: break-word;
	}
	tr {
		max-width: 100% !important;
		box-sizing: border-box;
		word-break: break-word;
	}
	td {
		max-width: 100% !important;
		box-sizing: border-box;
		word-break: break-word;
	}
	p {
		max-width: 100% !important;
		box-sizing: border-box;
		word-break: break-word;
		margin: 0 !important;
		padding: 0 !important;
		-webkit-margin-before: 0 !important;
		-webkit-margin-after: 0 !important;
	}
	span {
		max-width: 100% !important;
		box-sizing: border-box;
		word-break: break-word;
	}
	div {
		max-width: 100% !important;
		box-sizing: border-box;
		word-break: break-word;
	}
	ol {
		list-style-type: none !important;
	}

	ul {
		list-style-type: none !important;
	}

	li {
		list-style-type: none !important;
	}
}

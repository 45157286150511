.inter-pro-wrap-bit {
  // padding-top: 97px;
  padding-bottom: 40px;
  margin: 0 auto;
  width: 1224px;

  .bit-document {
    margin-top: 56px;

    &-card {
      width: 100%;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      margin-top: 40px;

      .page-title {
        font-size: 24px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        line-height: 32px;
        padding: 32px 32px 40px;
        position: relative;
        margin-bottom:0;
      }
      .page-sub-title {
        position: absolute;
        right: 0;
        font-size: 14px;
        color: #00408c;
        cursor: pointer;
      }
      .line {
        width: 100%;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.04);
        margin-bottom: 24px;
      }
      table,
      td,
      th {
        font-size: 14px;
        vertical-align: middle;
        padding: 0 32px 0;
        line-height: 42px;
        color: rgba(0, 0, 0, 0.56);
      }
      th {
        height: 48px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        background: #dae0e8;
      }
      td {
        line-height: 22px;
      }
      table {
        width: 100%;
      }
    }
  }

  .ant-table-cell {
    cursor: pointer;
  }

  .ant-table-cell:nth-child(2) {
    text-align: right;
  }
  .ant-table-row:hover {
    .ant-table-cell {
      color: #00408c;
    }
  }

  .ant-pagination {
    text-align: right;
    padding: 24px 32px 16px;
    margin-top:0;
  }
}
.header-img {
  margin-top: 100px;
  img {
    width: 100%;
    height: 370px;
  }
}

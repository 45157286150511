.buy_btn_wrap {
  // width: 120px;
  height: 40px;
  background: linear-gradient(
    132.53deg,
    rgba(255, 54, 44, 0.8) 0%,
    rgba(255, 176, 132, 0.8) 100%
  );
  border-radius: 8px;
  padding: 10px 16px;
  position: relative;

  &:hover {
    z-index: 1000;
    background: linear-gradient(
      132.53deg,
      rgba(255, 54, 44, 1) 0%,
      rgba(255, 176, 132, 1) 100%
    );
  }

  .title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    line-height: 16px;
    margin-right: 8px;
  }
  .icon {
    width: 16px;
    height: 16px;
  }

  .qrcode {
    position: absolute;
    right: -220px;
    bottom: 0;
    width: 200px;
    height: 228px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.08);
    padding: 20px;
    text-align: center;
    #canvas {
      width: 160px;
      height: 160px;
    }

    .arrow {
      position: relative;
      bottom: 14px;
      left: -32px;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-right: 12px solid #fff;
      border-bottom: 16px solid transparent;
    }

    .text {
      font-size: 14px;
      color: rgba(225, 37, 27, 1);
      line-height: 20px;
    }
  }

  @media screen and (max-width: 1600px) {
    .qrcode {
      top: -242px;
      right: 0;
      .arrow {
        position: relative;
        bottom: -21px;
        left: 122px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 16px solid #fff;
      }
    }
  }
}

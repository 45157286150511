.agreement-dialog {
	width: 900px;
	height: 580px;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	box-sizing: border-box;

	//&_body {
	//	width: 100%;
	//	flex: 1;
	//	display: flex;
	//	flex-shrink: 0;
	//	box-sizing: border-box;
	//	overflow-y: auto;
	//}

}

.detail-radio {
	box-sizing: border-box;

	&__style4 {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		min-width: 92px;
		height: 32px;
		line-height: 24px;
		border-radius: 6px;
		margin-right: 8px;
		cursor: pointer;
		font-size: 14px;
		border: 1px solid #E9E9E9;
		background-color: transparent;
		color: rgba(0, 0, 0, 0.64);
		font-weight: normal;
		&:hover {
			border: 1px solid rgba(225, 37, 27, 0.56);
			color: #E1251B;
		}

	}

	&--active {

		.detail-radio__style4 {
			border: 1px solid #E1251B;
			background-color: #E1251B;
			color: white;
			font-weight: bold;
			&:hover {
				color: white;
			}
		}

	}

	&--disabled {

		.detail-radio__style4 {
			border: 1px solid #d9d9d9;
			background-color: #f5f5f5;
			color: rgba(0, 0, 0, 0.25);
			font-weight: normal;
			cursor: not-allowed;
		}

	}

	&:nth-last-child(1) {

		.detail-radio__style4 {
			margin-right: 0;
		}

	}

}

.relection_recommend_wrap {
  width: 100%;
  background: #f9fafa;
  padding: 17px 0 0px 0;
}
.relection_recommend {
  width: 1224px;
  margin: 0 auto;
  .commend_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    .title_left {
      display: flex;
      align-items: center;
      .commend_bigTit {
        font-size: 40px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
      }
      .des {
        margin-left: 16px;
        width: 166px;
        height: 36px;
        border: 1px solid rgba(18, 0, 0, 0.08);
        border-radius: 18px;
        font-size: 16px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.56);
        text-align: center;
        line-height: 36px;
      }
    }
    .title_right {
      font-size: 16px;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.56);

      .title_right_tag {
        &:not(:last-child)::after {
          content: " | ";
          color: rgba(0, 0, 0, 0.16);
        }
      }
    }
  }
  .kind {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;

    .kind_all {
      display: flex;
      flex-wrap: wrap;

      .product_for {
        cursor: pointer;
        text-align: center;
        min-width: 118px;
        height: 40px;
        line-height: 40px;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        font-size: 18px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.8);
        margin-right: 14px;
        margin-bottom: 10px;
        text-align: center;
        padding: 0 12px;
      }
      ._product_for {
        cursor: pointer;
        min-width: 118px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: linear-gradient(
          90deg,
          rgba(225, 37, 27, 0.95) 0%,
          rgba(236, 49, 39, 0.95) 49.409252979385684%,
          rgba(255, 91, 49, 0.95) 100%
        );
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        margin-right: 14px;
        text-align: center;
        padding: 0 12px;
      }
    }
    .more {
      font-size: 14px;
      font-weight: normal;
      color: rgba(225, 37, 27, 1);
      display: flex;
      justify-content: center;
      cursor: pointer;
      &:hover {
        opacity: 0.72;
      }
      .more_mesg {
        line-height: 20px;
        margin-right: 8px;
        margin-bottom: 10px;
      }
      .more_img {
        width: 16px;
        height: 9px;
        img {
          width: 100%;
        }
      }
    }
  }
  .desc {
    display: flex;
    margin-top: 20px;
    align-items: flex-end;
    .desc_img {
      width: 43px;
      height: 40px;
      margin-right: 16px;
      opacity: 0.8;
      img {
        width: 100%;
      }
    }
    .desc_mesg {
      font-size: 14px;
      font-weight: normal;
      color: rgba(167, 113, 97, 1);
    }
  }
  .recommend {
    margin-top: 40px;
    display: flex;
    // justify-content: space-between;
    .recommend_product {
      margin-right: 24px;
      width: 392px;
      // height: 384px;
      padding-bottom: 33px;
      background: rgba(255, 255, 255, 1);
      border-radius: 8px;
      box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.04);
      &:hover {
        box-shadow: 0 24px 56px 0 rgba(0, 0, 0, 0.12);
        transition: all 0.2s;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      .recomend_img {
        height: 195px;
        img {
          width: 100%;
          height: 195px;
          border-radius: 8px;
        }
      }
      .product_info {
        background-color: rgba(255, 255, 255, 1);
        padding: 24px 32px 0px 32px;
        text-align: left;
        .product_name {
          font-size: 24px;
          height: 66px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.8);
          line-height: 33px;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 控制文本的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%; /* 根据需要调整宽度 */
        }
        .product_description {
          height: 44px;
          font-size: 16px;
          font-weight: normal;
          color: rgba(0, 0, 0, 0.56);
          line-height: 22px;
          margin-top: 8px;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 控制文本的行数 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%; /* 根据需要调整宽度 */
        }

        .product_operation {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 21px;
        }

        .product_operation .product_price {
          display: flex;
          align-items: baseline;
          font-size: 24px;
          line-height: 33px;
          font-weight: 600;
          color: rgba(225, 37, 27, 1);
          min-width: 0; /* 允许缩小到最小宽度，避免溢出 */
        }

        .product_operation .product_price .price {
          white-space: nowrap;
          overflow: hidden;
          // text-overflow: ellipsis;
          flex-grow: 1; /* 允许伸缩以填充可用空间 */
        }

        .product_operation .product_price .price_start {
          line-height: 27px;
          font-size: 16px;
          font-weight: normal;
          color: rgba(0, 0, 0, 0.8);
          flex-shrink: 0; /* 防止缩小，确保"元/起"始终可见 */
        }

        .jumpBtn {
          // width: 120px;
          height: 40px;
          background: linear-gradient(
            132deg,
            rgba(255, 54, 44, 0.8) 0%,
            rgba(255, 176, 132, 0.8) 100%
          );
          border: none;
          border-radius: 8px;
          padding: 8px 16px;
          position: relative;

          &:hover {
            z-index: 1000;
            background: linear-gradient(
              132deg,
              rgba(255, 54, 44, 1) 0%,
              rgba(255, 176, 132, 1) 100%
            );
          }

          .title {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 600;
            color: rgba(255, 255, 255, 1);
            line-height: 16px;
            // margin-right: 8px;
          }
        }
      }
    }
  }
}
.recommend_product:hover {
  box-shadow: 0 24px 56px 0 rgba(0, 0, 0, 0.12);
  transition: all 0.2s linear;
}

.common-page-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 40px;
  }

  .qa_nav_wrap {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 40px;
    cursor: pointer;
    .qa_nav {
      margin-right: 40px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.56);
      padding-bottom: 12px;

      &.active {
        font-weight: 600;
        color: rgba(225, 37, 27, 1);
        border-bottom: 2px solid rgba(225, 37, 27, 1);
        margin-bottom: -1px;
      }
    }
  }

  .btn_nav_wrap {
    display: flex;
    margin-bottom: 24px;

    .btn_nav {
      height: 32px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      line-height: 32px;
      padding: 0 26px;
      margin-right: 16px;
      cursor: pointer;

      &.active {
        color: #fff;
        background: rgba(225, 37, 27, 1);
        border-color: transparent;
      }
    }
  }

  .p_wrap {
    .year_title {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      line-height: 22px;
      // margin-bottom: 16px;
    }

    .year_item_wrap {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .line_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(240, 241, 242, 1);
      padding: 12px 0;
      font-size: 14px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.72);
      &:hover {
        color: rgba(0, 64, 140, 1);
      }
      .line_img {
        img {
          width: 16px;
          height: 16px;
        }
        .down_icon {
          // color: red;
        }
      }
    }
  }
}

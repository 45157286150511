// 给官网添加灰色滤镜
// html {
//   -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
//   filter: grayscale(100%);
// }
body {
  margin: 0;
  font-family: PingFangSC, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1366px;

  /* 分页器样式处理 */
  .ant-pagination {
    text-align: right;
    margin-top: 16px;
  }
  .ant-pagination-total-text,
  .ant-pagination-options-quick-jumper {
    font-size: 14px;
    color: rgba(36, 48, 69, 0.56);
  }

  .ant-pagination-options {
    .ant-select {
      .ant-select-selector {
        height: 32px;
        padding: 0 1.2rem;
      }
    }
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-item,
  .ant-pagination-next .ant-pagination-item-link,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-pagination-options-quick-jumper input {
    border: 1px solid #dee5ed;
    border-radius: 5px;
  }
  .ant-pagination-item-active,
  .ant-pagination-item:hover,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-options-quick-jumper input:hover {
    border-color: #ea281e;
    a,
    span {
      color: #ea281e;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  a {
    color: rgba(0, 64, 140, 1);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}
.flex_start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-between-start {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.noselect {
  user-select: none;
}

.hander {
  cursor: pointer;
}
.link:hover {
  border-bottom: 1px solid;
  color: #00408c;
}

.order-module {
	width: 100%;
	min-height: 100px;
	background-color: white;
	border-radius: 10px;
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 16px 20px 24px;
	flex-shrink: 0;
	margin-top: 20px;

	.order-module-title-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		line-height: 24px;
	}

	.order-module-title {
		font-size: 17px;
		color: rgba(0, 0, 0, 0.85);
		font-weight: bold;
	}

	.order-module-desc {
		font-size: 14px;
		color: rgba(153, 153, 153, 1);
	}

	.order-module-title-tag {
		position: absolute;
		top: 22px;
		left: 0;
		width: 3px;
		height: 12px;
		background-color: rgba(225, 37, 27, 1);
	}

}

.open-day-wrap {
  padding-top: 97px;
  padding-bottom: 40px;
  margin: 0 auto;
  width: 1224px;
  .open-day-container {
    margin-top: 56px;
    text-align: center;
    .page-title {
      font-size: 24px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      line-height: 32px;
      margin-bottom: 24px;
    }
    .page-subtitle {
      line-height: 20px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.56);
      margin-bottom: 24px;

      span {
        color: #e1251b;
        font-weight: 600;
      }
    }
    .line {
      width: 100%;
      height: 2px;
      background-color: rgba(0, 0, 0, 0.04);
      margin-bottom: 24px;
    }

    .table-title {
      text-align: center;
      line-height: 26px;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 24px;
      span {
        color: #e1251b;
        font-weight: 600;
      }
    }

    table,
    td,
    th {
      font-size: 12px;
      vertical-align: middle;
      text-align: center;
      border: 1px solid rgba(0, 0, 0, 0.08);
      padding: 0px 16px;
      line-height: 42px;
      color: rgba(0, 0, 0, 0.56);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    th {
      height: 48px;
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      background: #dae0e8;
    }
    td.table-td{
      white-space: normal;
      padding:0
    }
    table {
      width: 100%;
      margin-bottom: 56px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

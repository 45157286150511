.person-info-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 40px;
  }

  .tab_wrap {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 36px;

    .tab_item {
      line-height: 24px;
      font-size: 16px;
      margin-right: 80px;
      color: rgba(0, 0, 0, 0.56);
      padding-bottom: 14px;
      cursor: pointer;

      &.active {
        color: #ea281e;
        font-weight: 600;
        border-bottom: 2px solid #ea281e;
        margin-bottom: -1px;
      }
    }
  }

  .p_wrap {
    .year_title {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      line-height: 22px;
      // margin-bottom: 16px;
    }
    .line_item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(240, 241, 242, 1);
      padding: 12px 0;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        .line_date,
        .line_title {
          color: rgba(0, 64, 140, 1);
        }
      }
      .line_title {
        color: rgba(0, 0, 0, 0.72);
      }

      .line_date {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .box {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 40px;
    .box_header {
      background: rgba(0, 0, 0, 0.02);
      height: 48px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
      display: flex;
      .header_item {
        padding: 12px 16px;
        width: 20%;
        font-size: 14px;
        box-sizing: border-box;
        &:nth-child(2){
          width: 60%;
        }
      }
    }

    .box_body {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      &:last-child {
        border-bottom: none;
      }
      .body_item {
        line-height: 18px;
        padding: 12px 16px;
        line-height: 18px;
        width: 20%;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.56);
        &:nth-child(2){
          width: 60%;
          cursor: pointer;
        }
        &:nth-child(3){
          cursor: pointer;
        }
      }

      .w_2 {
        width: calc(100% / 3 * 2);
      }
    }

    .body_item_border {
      border-left: 1px solid rgba(0, 0, 0, 0.08);
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }

    .border_item_left {
      border-left: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
}

.order-header {
	width: 100%;
	min-height: 200px;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	padding-top: 20px;
	padding-bottom: 30px;

	&_left {
		display: flex;
		flex-direction: column;
		align-items: center;
		box-sizing: border-box;
		.v-img-banner {
			width: 288px;
			height: 260px;
			border-radius: 10px;
			object-fit: cover;
			object-position: top;
		}
		.v-feature {
			//max-width: 288px;
			height: 32px;
			background-color: white;
			outline: 1px solid rgba(233, 233, 233, 1);
			border-radius: 23px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			margin-top: 24px;
			padding-left: 36px;
			padding-right: 36px;
			.v-btn {
				padding: 0;
				margin: 0;
				color: #546AAF;
				font-size: 13px;
				&:hover {
					color: #E1251B;
				}
			}
			.v-gap {
				width: 1px;
				height: 10px;
				background-color: #BBBBBB;
				margin-left: 13px;
				margin-right: 13px;
			}
		}
	}

	&_right {
		display: flex;
		flex-direction: column;
		flex: 1;
		box-sizing: border-box;
		margin-left: 24px;

		.v-desc {
			width: 100%;
			display: inline-block;
			vertical-align: middle;
			margin-top: 6px;
			line-height: 20px;
			position: relative;
			box-sizing: border-box;
			font-size: 12px;
			color: #999999;
		}

		.v-title-wrap {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;
			position: relative;
			line-height: 26px;
		}

		.v-qrcode {
			height: 26px;
			background-color: transparent;
			border-radius: 13px;
			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;
			padding-left: 8px;
			padding-right: 8px;
			margin-left: 8px;
			&:hover {
				background-color: rgba(244, 244, 244, 1);
			}
			.v-img-qrcode {
				width: 16px;
				height: 16px;
				margin-right: 6px;
			}
			.v-text-qrcode {
				font-size: 12px;
				font-weight: normal;
				color: rgba(102, 102, 102, 1);
				line-height: 12px;
			}
		}

		.v-name {
			font-size: 24px;
			color: #000000;
			font-weight: bold;
			//line-height: 28px;
		}
		.v-tips-wrap {
			width: 100%;
			border-radius: 6px;
			background-color: #F9FAFB;
			margin-top: 12px;
			padding: 10px 16px;
			box-sizing: border-box;
		}
		.v-tips-row {
			width: 100%;
			display: block;
			//display: flex;
			//flex-direction: row;
			//flex-wrap: wrap;
			//align-items: flex-start;
			box-sizing: border-box;
			line-height: 20px;
			margin-top: 4px;
		}
		.v-tips-row-item {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			margin-right: 20px;
			margin-bottom: 4px;
			font-size: 14px;
			font-weight: normal;
			color: rgba(0, 0, 0, 0.85);
		}
		.v-plan-header {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;
			margin-top: 16px;
			line-height: 20px;
			&_left {
				//font-size: 12px;
				font-size: 18px;
				//color: #8C8C8C;
				color: #000000;
				font-weight: bold;
			}
			&_right {
				font-size: 12px;
				//color: #E1251B;
				color: rgba(225, 37, 27, 1);
				font-weight: bold;
				cursor: pointer;
				display: flex;
				flex-direction: row;
				align-items: center;
				box-sizing: border-box;
				&:hover {
					opacity: 0.72;
				}
			}
			.v-img-detail {
				width: 16px;
				height: 16px;
				margin-left: 4px;
			}
		}
		.v-sub-name {
			font-size: 12px;
			color: #8C8C8C;
			font-weight: bold;
			margin-top: 16px;
			line-height: 20px;
		}
		.v-text-prompt {
			font-size: 16px;
			color: #000000;
			font-weight: bold;
		}
		.v-plan-detail {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-between;
			box-sizing: border-box;
		}
		.v-plan-detail-group-item-divider {
			width: 100%;
			height: 1px;
			//background-color: #E9E9E9;
			background-color: rgba(240, 240, 241, 1);
			margin-top: 10px;
			margin-bottom: 6px;
		}
		.v-plan-detail-group-item-wrap {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			box-sizing: border-box;
			flex-shrink: 0;
		}
		.v-plan-detail-group-item {
			width: 425px;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			box-sizing: border-box;
			line-height: 24px;
			margin-top: 4px;
			font-size: 15px;
			font-weight: normal;
			color: rgba(51, 51, 51, 1);
			flex-shrink: 0;
			&_left {
				flex: 1;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: center;
				box-sizing: border-box;
				padding-right: 20px;
				font-weight: bold;
			}
			&_right {
				color: #000000;
				font-weight: bold;
			}
		}
		.v-plan-detail-item {
			width: 425px;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			box-sizing: border-box;
			line-height: 20px;
			margin-top: 4px;
			font-size: 14px;
			font-weight: normal;
			color: rgba(51, 51, 51, 1);
			flex-shrink: 0;
			&_left {
				flex: 1;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				align-items: center;
				box-sizing: border-box;
				padding-right: 20px;
			}
			&_right {
				color: #000000;
				//font-weight: bold;
			}
		}
		.v-optional-wrap {
			padding-left: 16px;
			box-sizing: border-box;
			position: relative;
		}
		.v-list {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-wrap: wrap;
			box-sizing: border-box;
		}
		.v-plan-item {
			min-width: 220px;
			min-height: 60px;
			outline: 1px solid #E9E9E9;
			background-color: transparent;
			border-radius: 8px;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			padding: 10px;
			margin-top: 10px;
			margin-right: 10px;
			cursor: pointer;
			color: rgba(0, 0, 0, 0.85);
			font-weight: normal;
			&:nth-child(n+4) {
				margin-right: 0;
			}
			&:hover {
				outline: 1px solid rgba(225, 37, 27, 0.56);
			}
		}
		.v-plan-item-active {
			outline: 1px solid #E1251B;
			background-color: #E1251B;
			color: white;
			font-weight: bold;
			&:hover {
				outline: 1px solid #E1251B;
			}
		}
		.v-strong {
			font-weight: bold;
		}
		.v-optional-item {
			min-width: 220px;
			min-height: 60px;
			outline: 1px solid #E9E9E9;
			border-radius: 8px;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			margin-top: 10px;
			margin-right: 10px;
			cursor: pointer;
			position: relative;
			padding: 10px 10px 10px 34px;
			background-color: transparent;
			color: #262626;
			&:nth-child(n+4) {
				margin-right: 0;
			}
			&:hover {
				outline: 1px solid rgba(225, 37, 27, 0.56);
			}
		}
		.v-optional-item-active {
			outline: 1px solid #E1251B;
			background-color: #FFF8F8;
			color: #E1251B;
			&:hover {
				outline: 1px solid #E1251B;
			}
		}
		.v-plan-wrap {
			width: 100%;
			border-radius: 6px;
			background-color: #F9FAFB;
			margin-top: 8px;
			padding: 10px 16px;
			box-sizing: border-box;
			position: relative;
		}

		.v-plan-detail-divider {
			width: 1px;
			background-color: rgba(240, 240, 241, 1);
			position: absolute;
			top: 40px;
			bottom: 16px;
			left: 455px;
			z-index: 2;
		}

		.v-optional-checkbox {
			position: absolute;
			top: 13px;
			left: 10px;
		}

		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: #E1251B;
			border-color: #E1251B;
		}

	}

}

.empty-info-wrap {
  .title {
    height: 33px;
    line-height: 33px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 24px;
  }

  .empty_wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 520px;

    img {
      width: 150px;
      height: 160px;
      margin-bottom: 24px;
    }
    p {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}

.order-form {
	width: 100%;
	min-height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	background-color: #F6F7F8;

	.v-breadcrumb {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		background-color: white;
		padding-top: 24px;
		padding-bottom: 20px;
		border-bottom: 1px solid #F6F7F8;
	}

	.v-breadcrumb-img {
		width: 12px;
		height: 12px;
		margin-right: 8px;
	}

	.v-breadcrumb-item {
		font-size: 12px;
		font-weight: normal;
		color: rgba(0, 0, 0, 0.36);
		cursor: pointer;
		margin-right: 8px;
		line-height: 12px;
		//&:hover {
		//	color: #546AAF;
		//}
	}
	.v-breadcrumb-item-active {
		font-size: 12px;
		font-weight: normal;
		color: rgba(0, 0, 0, 0.72);
		line-height: 12px;
	}

	&_header {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		box-sizing: border-box;
		background-color: white;
		flex-shrink: 0;
	}

	&_form {
		width: 1224px;
		flex-shrink: 0;
	}

	&_footer {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: white;
		box-sizing: border-box;
		box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.04);
		margin-top: 20px;
		flex-shrink: 0;
		position: sticky;
		z-index: 4;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.nav_wrap {
  width: 250px;
  padding: 18px 0;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  height: 100%;
  .nav_item {
    .nav_item_inner {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.72);
      padding-right: 24px;
      // border-bottom: 1px solid rgba(240, 241, 242, 1);
      cursor: pointer;

      .inner_menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        p {
          padding-left: 24px;
        }
      }

      &.active {
        font-weight: 600;
        color: #e1251b;
        &::before {
          display: inline-block;
          content: '';
          width: 2px;
          height: 24px;
          background-color: rgba(225, 37, 27, 1);
        }
      }

      &.p_active {
        font-weight: 600;
      }
      .nav_item_p {
        height: 54px;
        line-height: 54px;
        font-size: 16px;
      }

      .img {
        width: 16px;
        height: 16px;
        rotate: 180deg;
        color: rgba(0, 0, 0, 0.4);

        &.open {
          transform: rotate(180deg);
          transition: all 0.6s;
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }

  .dia_line {
    height: 1px;
    background-color: rgba(240, 241, 242, 1);
    width: calc(100% - 48px);
    margin: 0 auto;
  }

  .sub_item_wrap {
    padding: 8px 0;
  }

  .sub_item {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.48);

    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 24px;
    font-size: 14px;
    display: flex;
    &.active {
      color: #e1251b;
      &::before {
        display: inline-block;
        content: '';
        width: 2px;
        height: 24px;
        background-color: rgba(225, 37, 27, 1);
      }
    }
    span {
      padding-left: 24px;
      border-bottom: none;
    }
  }
}
